/* Styles for the sidebar scrollbar */
.sidebar::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #020163; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 2px solid #020163; /* creates padding around scroll thumb */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #020163; /* color of the scroll thumb when hovered over */
}
