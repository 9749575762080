.lg-react-element {
  column-count: 3;
  column-gap: 1px;
  padding: 25px;
}

.gallery {
  max-width: 100%;
  display: block;
  padding: 5px 0px;
  border-radius: 20px;
  transition: transform 0.2s;
}

.gallery:hover {
  filter: opacity(0.9);
  transform: scale(1.01);
}

.hoverable-container {
  position: relative;
  display: inline-block; /* Or however you want to handle layout */
}

.hover-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.618); /* Semi-transparent background */
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.hoverable-container:hover .hover-content {
  visibility: visible;
  opacity: 1;
}
