html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600;700&family=Rubik:wght@400;500;600;700&display=swap");
@font-face {
  font-family: Muli;
  src: url(../fonts/muli-extra-light.ttf);
  font-weight: 200;
}
@font-face {
  font-family: Muli;
  src: url(../fonts/muli-light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: Muli;
  src: url(../fonts/muli.ttf);
  font-weight: 400;
}
@font-face {
  font-family: Muli;
  src: url(../fonts/muli-semi-bold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: Muli;
  src: url(../fonts/muli-semi-bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: Prata;
  src: url(../fonts/prata-regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: monotype-corsiva;
  src: url(../fonts/prata-regular.ttf);
  font-weight: 400;
}
@keyframes drifty {
  0% {
    transform: skewY(10deg) translate3d(-250%, 0, 0);
    display: block;
  }
  100% {
    transform: skewY(-12deg) translate3d(250%, 0, 0);
    display: block;
  }
}
@keyframes driftyRotate {
  0% {
    transform: rotateX(0);
    display: block;
    opacity: 1;
  }
  100% {
    transform: rotateX(359deg);
    display: block;
    opacity: 0;
  }
}

@keyframes driftyRotate {
  0% {
    transform: rotateX(0);
    display: block;
    opacity: 1;
  }
  100% {
    transform: rotateX(359deg);
    display: block;
    opacity: 0;
  }
}
@keyframes pataAboutTop1 {
  0% {
    top: -90px;
    left: 50px;
  }
  100% {
    top: 100%;
    left: 70%;
  }
}
@keyframes pataAboutTop2 {
  0% {
    top: -90px;
    left: 30px;
  }
  100% {
    top: 100%;
    left: 40px;
  }
}
@keyframes pataAboutTop3 {
  0% {
    top: -90px;
    left: 30px;
  }
  100% {
    top: 100%;
    left: 0;
  }
}
@keyframes pataAboutTop4 {
  0% {
    top: -90px;
    left: 30px;
  }
  100% {
    top: 100%;
    left: 30px;
  }
}
@keyframes pataAboutTop5 {
  0% {
    top: -90px;
    left: 30px;
  }
  100% {
    top: 60%;
    left: 80%;
  }
}
@keyframes pataAboutTop6 {
  0% {
    top: -90px;
    left: 30px;
  }
  100% {
    top: 70%;
    left: 80%;
  }
}
@keyframes pataAboutTop7 {
  0% {
    top: -90px;
    left: 30px;
  }
  100% {
    top: 70%;
    left: 30px;
  }
}
@-webkit-keyframes blo-danceBottom {
  16% {
    -webkit-transform: skew(-14deg);
    transform: skew(-14deg);
  }
  33% {
    -webkit-transform: skew(12deg);
    transform: skew(12deg);
  }
  49% {
    -webkit-transform: skew(-8deg);
    transform: skew(-8deg);
  }
  66% {
    -webkit-transform: skew(6deg);
    transform: skew(6deg);
  }
  83% {
    -webkit-transform: skew(-4deg);
    transform: skew(-4deg);
  }
}
@keyframes blo-danceBottom {
  16% {
    -webkit-transform: skew(-14deg);
    transform: skew(-14deg);
  }
  33% {
    -webkit-transform: skew(12deg);
    transform: skew(12deg);
  }
  49% {
    -webkit-transform: skew(-8deg);
    transform: skew(-8deg);
  }
  66% {
    -webkit-transform: skew(6deg);
    transform: skew(6deg);
  }
  83% {
    -webkit-transform: skew(-4deg);
    transform: skew(-4deg);
  }
}
@-webkit-keyframes blo-blurInTop {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-filter: blur(20px);
    filter: blur(20px);
    opacity: 0;
  }
}
* {
  box-sizing: border-box;
  /* overflow-x: auto; */
}

body {
  font-family: "Muli", sans-serif;
  font-size: 15px;
  font-weight: normal;
  color: var(--clr-body-text);
  line-height: 28px;
  margin: 0;
  padding: 0;
  overflow-x: none;
}

a {
  text-decoration: none;
}

.w-img img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  /* margin-top: 20px;
  margin-left: 50px; */
}

.svg_img {
  margin-top: 20px;
  margin-left: 50px;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

/* a,
button {
  color: inherit;
  outline: none;

  background: transparent;
} */

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
  border: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}
.lg-icon.lg-zoom-in:after {
  display: none;
}
.lg-toolbar .lg-download:after {
  display: none;
}

span {
  font-family: "Prata", serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Prata", serif;
  color: #020163;

  margin-top: 0px;
  font-weight: 500;
  line-height: 1.13;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: 300;
  /* color: var(--clr-body-text); */
  margin-bottom: 15px;
  line-height: 26px;
}

*::-moz-selection {
  background: var(--clr-theme-primary);
  color: var(--clr-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--clr-theme-primary);
  color: var(--clr-common-white);
  text-shadow: none;
}

::selection {
  background: var(--clr-theme-primary);
  color: var(--clr-common-white);
  text-shadow: none;
}

/*--
    - Input Placeholder
-----------------------------------------*/
*::-moz-placeholder {
  color: var(--clr-theme-black);
  font-size: 14px;
  opacity: 1;
}

*::placeholder {
  color: var(--clr-theme-black);
  font-size: 14px;
  opacity: 1;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.z-index-1 {
  z-index: 1;
}

.z-index-11 {
  z-index: 11;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

/*--
    - Background color
-----------------------------------------*/
.grey-bg {
  background: var(--clr-common-gray);
}

.theme-bg {
  background-color: var(--clr-theme-primary);
}

.white-bg {
  background: var(--clr-common-white);
}

.black-bg {
  background: var(--clr-theme-black);
}

.footer-bg {
  background: var(--clr-footer-bg);
}

/*--
    - color
-----------------------------------------*/
.white-color {
  color: var(--clr-common-white);
}

.theme-color {
  color: var(--clr-theme-primary) !important;
}

.black-color {
  color: var(--clr-theme-black);
}

.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.body-overlay:hover {
  cursor: pointer;
}

.body-overlay.opened {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 767px) {
  .progress-wrap {
    right: 15px;
    bottom: 15px;
  }
}

/* link btn */
.link-btn {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: var(--clr-theme-primary);
  text-transform: uppercase;
  padding-right: 15px;
}
.link-btn i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
}
.link-btn i:first-child {
  left: -100%;
  visibility: hidden;
  opacity: 0;
}
.link-btn i:last-child {
  right: 0;
}
.link-btn:hover {
  color: var(--clr-theme-black);
}
.link-btn:hover i:first-child {
  left: 0;
  visibility: visible;
  opacity: 1;
}
.link-btn:hover i:last-child {
  right: -100%;
  visibility: hidden;
  opacity: 0;
}

.link-btn-2 {
  position: relative;
  font-size: 14px;
  color: var(--clr-theme-primary);
  font-weight: 500;
  padding-right: 22px;
  display: inline-block;
  text-transform: uppercase;
  font-family: "Prata", serif;
}
.link-btn-2 i {
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  font-size: 12px;
}
.link-btn-2 i:first-child {
  right: 10%;
  visibility: hidden;
  opacity: 0;
}
.link-btn-2 i:last-child {
  right: 0;
}
.link-btn-2:hover {
  color: var(--clr-theme-primary);
}
.link-btn-2:hover i:first-child {
  right: 0;
  visibility: visible;
  opacity: 1;
}
.link-btn-2:hover i:last-child {
  right: -10%;
  visibility: hidden;
  opacity: 0;
}
.link-btn-2.pink {
  color: var(--clr-theme-primary);
}
.link-btn-2.pink:hover {
  color: var(--clr-theme-primary);
}

/* pulse btn */
.pulse-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 84px;
  text-align: center;
  background-color: var(--clr-common-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: var(--clr-theme-primary);
  animation: pulse 2s infinite;
}
.pulse-btn:hover {
  background-color: var(--clr-theme-primary);
  color: var(--clr-common-white);
}
.pulse-btn i {
  padding-left: 2px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.row {
  --bs-gutter-x: 30px;
}
.row.g-70 {
  --bs-gutter-x: 70px;
}
@media (max-width: 767px) {
  .row.g-70 {
    --bs-gutter-x: 0;
  }
}
.row.g-10 {
  --bs-gutter-x: 10px;
}
.row.g-15 {
  --bs-gutter-x: 15px;
}

.row.g-0 {
  --bs-gutter-x: 0;
}

@media (min-width: 1400px) {
  .container-2 {
    max-width: 1815px;
  }

  .container {
    padding: 0 15px;
    /* width: 900px; */
  }
}
/* <<<<<<<    Declaration   >>>>>>>>> */
:root {
  /**
  @color declaration
  */
  --clr-common-white: #fff;
  --clr-common-black: #111111;
  --clr-common-black-2: #141517;
  --clr-common-black-3: #575757;
  --clr-body-text: #777777;
  --clr-body-grey: #777777;
  --clr-body-grey-2: #707070;
  --clr-body-grey-3: #e2e2e2;
  --clr-body-grey-4: #6a6a6a;
  --clr-body-grey-5: #f8f8f8;
  --clr-body-grey-6: #aaaaaa;
  --clr-body-grey-7: #c5c5c5;
  --clr-body-grey-8: #979797;
  --clr-body-grey-9: #ececec;
  --clr-body-grey-soft: #e7e7e7;
  --clr-theme-primary: #dfb68d;
  --clr-theme-primary-light: #f9efe5;
  --clr-heading-primary: #111111;
  --clr-footer-bg: #111111;
  --clr-overlay-black: #0d0b09;
  --clr-bg-theme-soft: #f8f6f4;
  --clr-border-light: #f2f2f2;
  --clr-border-light_2: #f9f9f9;
  --clr-border-light_3: #e1e1e1;
  --clr-border-light_4: #dfdfdf;
  --clr-border-dark: #75716d;
  --clr-border-soft: #e9e9e9;
  --clr-border-soft-2: #ebebeb;
}

.p-rel {
  position: relative;
}

.pb-335 {
  padding-bottom: 335px;
}

.pb-615 {
  padding-bottom: 615px;
}

img {
  max-width: 100%;
}

.lv-bg-opacity {
  position: relative;
}
.lv-bg-opacity::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--clr-common-black);
}
.lv-bg-opacity .container {
  position: relative;
  z-index: 3;
}
.lv-bg-opacity.lv-opacity-5::after {
  opacity: 0.5;
}

.lv-body-content {
  padding: 100px 335px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .lv-body-content {
    padding: 65px 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-body-content {
    padding: 65px 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-body-content {
    padding: 70px 29px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-body-content {
    padding: 30px 30px;
  }
}
@media (max-width: 767px) {
  .lv-body-content {
    padding: 15px 15px;
  }
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-5-px {
  margin-bottom: 5px;
}

.pr-52 {
  padding-right: 52px;
}

.pl-52 {
  padding-left: 52px;
}

.pb-13 {
  padding-bottom: 13px;
}

.bg-top-center {
  background-position: center top !important;
}

.mb-43 {
  margin-bottom: 43px;
}

.pt-240 {
  padding-top: 240px;
}

.lv-brand-negative-space-2 {
  margin-top: -123px;
}

@media (min-width: 1600px) and (max-width: 1800px) {
  .lv-body-content {
    padding: 100px 145px;
  }
}
.bg-attached {
  background-attachment: fixed;
}

.jarallax-img {
  transition: all 0s;
}

.obj-cover {
  object-fit: cover;
}

/**
********************************************
************* 2.Button
********************************************
*/
.lv-transparent-btn {
  display: inline-block;
  height: 50px;
  line-height: 47px;
  color: var(--clr-common-white);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 50px;
  position: relative;
  -webkit-backface-visibility: hidden;
  border: 1px solid rgba(255, 255, 255, 0.6);
}
.lv-transparent-btn:hover {
  color: var(--clr-common-white);
}
.lv-transparent-btn:hover .lv-transparent-horizontal-line::before,
.lv-transparent-btn:hover .lv-transparent-horizontal-line::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.lv-transparent-btn:hover .lv-transparent-horizontal-line::before {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.lv-transparent-btn:hover .lv-transparent-horizontal-line::after {
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s;
}
.lv-transparent-btn:hover .lv-transparent-vertical-line::after,
.lv-transparent-btn:hover .lv-transparent-vertical-line::before {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}
.lv-transparent-btn:hover .lv-transparent-vertical-line::after {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.lv-transparent-btn:hover .lv-transparent-vertical-line::before {
  -webkit-transition-delay: 0.36s;
  -o-transition-delay: 0.36s;
  transition-delay: 0.36s;
}
.lv-transparent-horizontal-line::before,
.lv-transparent-horizontal-line::after {
  content: "";
  position: absolute;
  left: -1px;
  display: block;
  width: calc(100% + 2px);
  height: 1px;
  background-color: var(--clr-common-white);
  -webkit-transition: 0.36s ease-out, background-color 1ms;
  -o-transition: 0.36s ease-out, background-color 1ms;
  transition: 0.36s ease-out, background-color 1ms;
  transition-delay: 0s, 0s;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}
.lv-transparent-horizontal-line::before {
  top: -1px;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s;
}
.lv-transparent-horizontal-line::after {
  bottom: -1px;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.lv-transparent-vertical-line::before,
.lv-transparent-vertical-line::after {
  content: "";
  position: absolute;
  top: 0;
  display: block;
  height: 100%;
  width: 1px;
  background-color: var(--clr-common-white);
  -webkit-transition: 0.12s ease-out, background-color 1ms;
  -o-transition: 0.12s ease-out, background-color 1ms;
  transition: 0.12s ease-out, background-color 1ms;
  transition-delay: 0s, 0s;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
}
.lv-transparent-vertical-line::before {
  right: -1px;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.lv-transparent-vertical-line::after {
  left: -1px;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition-delay: 0.36s;
  -o-transition-delay: 0.36s;
  transition-delay: 0.36s;
}

.lv-about-play-btn-link {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 58px;
  position: relative;
  text-align: center;
  border-radius: 50%;
  outline: 1px solid var(--clr-border-soft);
  outline-offset: -3px;
}
.lv-about-play-btn-link svg {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  fill: none;
  stroke: var(--clr-common-black);
  -webkit-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03),
    stroke 10ms ease-out;
  -o-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03),
    stroke 10ms ease-out;
  transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03), stroke 10ms ease-out;
  transition-duration: 0.8s, 10ms;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  -webkit-transition-duration: 1.1s;
  outline: 1px solid #ffffff7d;
  border-radius: 50%;
  outline-offset: -10px;
  -o-transition-duration: 1.1s;
  transition-duration: 1.1s;
}
.lv-about-play-btn-link svg circle {
  fill: none;
  stroke: var(--clr-common-black);
  stroke-dasharray: 950;
  stroke-dashoffset: 950;
  stroke-width: 1;
  -webkit-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03);
  -o-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03);
  transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03);
  transition-duration: 0.8s;
  -webkit-transition-duration: 1.1s;
  -o-transition-duration: 1.1s;
  transition-duration: 1.1s;
}
.lv-about-play-btn-link:hover {
  color: var(--clr-common-black);
  outline-color: transparent;
}
.lv-about-play-btn-link:hover svg {
  -webkit-transform: rotate(-45deg) translateZ(0);
  transform: rotate(-45deg) translateZ(0);
}
.lv-about-play-btn-link:hover svg circle {
  stroke-dashoffset: 10;
}

.lv-theme-btn {
  display: inline-block;
  height: 50px;
  line-height: 47px;
  color: var(--clr-common-black);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 50px;
  position: relative;
  border: 1px solid #00000030;
  -webkit-backface-visibility: hidden;
  border: 1px solid rgba(0, 0, 0, 0.19);
}
.lv-theme-btn .lv-theme-horizontal-line::before,
.lv-theme-btn .lv-theme-horizontal-line::after {
  content: "";
  position: absolute;
  left: -1px;
  display: block;
  width: calc(100% + 2px);
  height: 1px;
  background-color: var(--clr-common-black);
  -webkit-transition: 0.36s ease-out, background-color 1ms;
  -o-transition: 0.36s ease-out, background-color 1ms;
  transition: 0.36s ease-out, background-color 1ms;
  transition-delay: 0s, 0s;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}
.lv-theme-btn .lv-theme-horizontal-line::before {
  top: -1px;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s;
}
.lv-theme-btn .lv-theme-horizontal-line::after {
  bottom: -1px;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.lv-theme-btn .lv-theme-vertical-line::before,
.lv-theme-btn .lv-theme-vertical-line::after {
  content: "";
  position: absolute;
  top: 0;
  display: block;
  height: 100%;
  width: 1px;
  background-color: var(--clr-common-black);
  -webkit-transition: 0.12s ease-out, background-color 1ms;
  -o-transition: 0.12s ease-out, background-color 1ms;
  transition: 0.12s ease-out, background-color 1ms;
  transition-delay: 0s, 0s;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
}
.lv-theme-btn .lv-theme-vertical-line::before {
  right: -1px;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.lv-theme-btn .lv-theme-vertical-line::after {
  left: -1px;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition-delay: 0.36s;
  -o-transition-delay: 0.36s;
  transition-delay: 0.36s;
}
.lv-theme-btn:hover {
  color: var(--clr-common-black);
}
.lv-theme-btn:hover .lv-theme-horizontal-line::before,
.lv-theme-btn:hover .lv-theme-horizontal-line::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.lv-theme-btn:hover .lv-theme-horizontal-line::before {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.lv-theme-btn:hover .lv-theme-horizontal-line::after {
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s;
}
.lv-theme-btn:hover .lv-theme-vertical-line::after,
.lv-theme-btn:hover .lv-theme-vertical-line::before {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}
.lv-theme-btn:hover .lv-theme-vertical-line::after {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.lv-theme-btn:hover .lv-theme-vertical-line::before {
  -webkit-transition-delay: 0.36s;
  -o-transition-delay: 0.36s;
  transition-delay: 0.36s;
}

@keyframes border-round {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: center;
  }
}
/**
********************************************
************* 3.Header
********************************************
*/
.lv-header-left ul li:not(:last-child) {
  margin-right: 35px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-header-left ul li:not(:last-child) {
    margin-right: 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-header-left ul li:not(:last-child) {
    margin-right: 15px;
  }
}
.lv-header-right ul {
  text-align: left;
  margin-left: 30px;
}
.lv-header-right ul li:not(:first-child) {
  margin-left: 35px;
}
.col-xl-5 {
  width: 44%;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-header-right ul li:not(:first-child) {
    margin-left: 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-header-right ul li:not(:first-child) {
    margin-left: 15px;
  }
}
.lv-header-logo img {
  max-width: 128.37px;
}
@media only screen and (max-width: 992px) and (max-width: 1199px) {
  .lv-header-logo img {
    max-width: 94.37px;
  }
}
@media only screen and (max-width: 768px) and (max-width: 991px) {
  .lv-header-logo img {
    max-width: 88.37px;
  }
}
.lv-header-logo-2 {
  text-align: center;
  position: relative;
  z-index: 4;
}
.lv-header-logo-2 a {
  display: inline-block;
  padding: 20px 0;
  position: relative;
}
@media (max-width: 767px) {
  .lv-header-logo-2 a {
    padding: 5px 0;
  }
}
.lv-header-logo-2 a::before {
  position: absolute;
  left: 50%;
  top: 0;
  width: 398.24px;
  height: 187.48px;
  content: "";
  background-image: url(../img/bg/logo-bar.webp);
  transform: translateX(-50%);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-header-logo-2 a::before {
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: top center;
  }
}
@media (max-width: 767px) {
  .lv-header-logo-2 a::before {
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    width: 268.24px;
  }
}
.lv-header-logo-2 img {
  max-width: 114.39px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-header-logo-2 img {
    max-width: 88.39px;
  }
}
@media (max-width: 767px) {
  .lv-header-logo-2 img {
    max-width: 87.39px;
  }
}
.lv-header-logo-3 img {
  max-width: 101.94px;
}
.lv-header-bar-1 a,
.lv-header-bar-2 a {
  width: 29.44px;
  height: 17.96px;
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
}
.lv-header-bar-1 a:hover .lv-header-bar-line-2,
.lv-header-bar-2 a:hover .lv-header-bar-line-2 {
  width: 100%;
}
.lv-header-bar-2 a span {
  background-color: var(--clr-common-black);
}
.lv-header-bar-line {
  transition: all 0.3s;
  height: 2px;
  display: inline-block;
  background: var(--clr-common-white);
  border-radius: 3px;
}
.lv-header-bar-line-1 {
  width: 29.44px;
}
.lv-header-bar-line-2 {
  width: 15.97px;
}
.lv-header-bar-line-3 {
  width: 29.44px;
}
.lv-header-nav-1 ul li {
  display: inline-block;
  position: relative;
  z-index: 90;
}
.col-xl-2 {
  z-index: 1;
}
.lv-header-nav-1 ul li a {
  color: var(--clr-common-white);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 5px;
  position: relative;

  display: inline-block;
}
.lv-header-nav-1 ul li a svg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  bottom: 0px;
  transition: all 0.3s;
}
.lv-header-nav-1 ul li:hover > a svg,
.lv-header-nav-1 ul li.active > a svg {
  width: 100%;
}
.lv-header-nav-1 ul li .submenu {
  background: var(--clr-common-white) none repeat scroll 0 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  left: 0;
  position: absolute;
  z-index: 99;
  top: 119%;
  transition: all 0.3s ease 0s;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  text-align: left;
  padding: 15px 0;
}
.lv-header-nav-1 ul li .submenu li {
  display: block;
  margin: 0px;
  padding: 0 21px;
}
.lv-header-nav-1 ul li .submenu li a {
  padding: 0px;
  display: inline-block;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--clr-body-black);
  position: relative;
}
.lv-header-nav-1 ul li .submenu li:hover > a {
  color: var(--clr-common-black);
}
.lv-header-nav-1 ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.lv-header-logo-box {
  position: relative;
}
.lv-header-logo-box::after {
  position: absolute;
  width: 301.24px;
  height: 145.48px;
  background-image: url(../img/bg/logo-bar.webp);
  content: "";
  background-size: cover;
  background-position: center;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: top center;
  /* z-index: 90; */
}

@media (max-width: 767px) {
  .lv-header-logo-box::after {
    width: 200px;
    height: 367.48px;
    background-size: 103%;
  }
}
.lv-header-logo-box a {
  position: relative;
  z-index: 3;
  padding: 20px 0;
  display: inline-block;
}
.lv-header-right {
  text-align: right;
}
.lv-header-shopping-btn {
  font-size: 25px;
  color: var(--clr-common-white);
  position: relative;
  position: relative;
}
.lv-header-shopping-btn span {
  position: absolute;
  right: -7px;
  color: var(--clr-common-black);
  top: 0;
  width: 17px;
  height: 17px;
  background: var(--clr-theme-primary);
  line-height: 15px;
  font-size: 14px;
  border-radius: 50%;
}
.lv-header-2,
.lv-header-3 {
  background: var(--clr-common-white) none repeat scroll 0 0;
}
.lv-header-2 ul li,
.lv-header-3 ul li {
  display: inline-block;
  position: relative;
}
.lv-header-2 ul li a,
.lv-header-3 ul li a {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  color: var(--clr-common-black);
  padding: 44px 0;
  line-height: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-header-2 ul li a,
  .lv-header-3 ul li a {
    font-size: 13px;
  }
}
.lv-header-2 ul li a svg,
.lv-header-3 ul li a svg {
  position: absolute;
  left: 50%;
  width: 0;
  transition: all 0.3s;
  bottom: 36px;
  transform: translateX(-50%);
}
.lv-header-2 ul li:hover > a svg,
.lv-header-3 ul li:hover > a svg {
  width: 100%;
}
.lv-header-2 ul li .submenu,
.lv-header-3 ul li .submenu {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  left: 0;
  position: absolute;
  z-index: 99;
  background-color: var(--clr-common-white);
  top: 119%;
  transition: all 0.3s ease 0s;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  text-align: left;
  padding: 15px 0;
}
.lv-header-2 ul li .submenu li,
.lv-header-3 ul li .submenu li {
  display: block;
  margin: 0px;
  padding: 0 21px;
}
.lv-header-2 ul li .submenu li a,
.lv-header-3 ul li .submenu li a {
  padding: 0px;
  display: inline-block;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--clr-body-black);
  position: relative;
}
.lv-header-2 ul li .submenu li:hover > a,
.lv-header-3 ul li .submenu li:hover > a {
  color: var(--clr-common-black);
}
.lv-header-2 ul li:hover > .submenu,
.lv-header-3 ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.lv-header-3 nav > ul > li {
  margin: 0 18px;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .lv-header-3 nav > ul > li {
    margin: 0 14px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .lv-header-3 nav > ul > li {
    margin: 0 8px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-header-3 nav > ul > li {
    margin: 0 16px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-header-3 nav > ul > li {
    margin: 0 10px;
  }
}
.lv-header-left-2 li:not(:last-child) {
  margin-right: 35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-header-left-2 li:not(:last-child) {
    margin-right: 27px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-header-left-2 li:not(:last-child) {
    margin-right: 18px;
  }
}
@media (min-width: 1400px) {
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 48.666667%;
  }
}
.lv-header-right-2 {
  text-align: right;
}
.lv-header-right-2 li:not(:first-child) {
  margin-left: 35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-header-right-2 li:not(:first-child) {
    margin-left: 27px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-header-right-2 li:not(:first-child) {
    margin-left: 18px;
  }
}

.bg-default {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 991px) {
  .lv-header-sidebar-wrapper .mean-container .mean-nav {
    float: none;
    background-color: var(--clr-theme-primary);
    margin-top: 0;
    padding: 0 0;
  }
  .lv-header-sidebar-wrapper .mean-container .mean-nav > ul {
    overflow: hidden;
    display: inline-block !important;
  }
  .lv-header-sidebar-wrapper .mean-container .mean-nav .submenu {
    overflow: hidden;
  }
  .lv-header-sidebar-wrapper .mean-container .mean-nav .submenu li {
    float: none;
  }
  .lv-header-sidebar-wrapper .mean-container .mean-bar {
    padding: 0;
    float: none;
    background: var(--clr-theme-primary);
    display: block;
  }
  .lv-header-sidebar-wrapper .mean-container .mean-bar ul li a svg {
    display: none;
  }
  .lv-header-sidebar-wrapper .mean-container a.meanmenu-reveal {
    display: none !important;
  }
  .lv-header-sidebar-wrapper .mean-container .mean-nav ul li a {
    border-color: #ffffff82;
  }
  .lv-header-sidebar-wrapper .mobile-menu {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-header-sidebar-logo.pb-50 {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .about-area-space-1 {
    padding-bottom: 120px;
  }
}

.lv-header-bar-1 {
  z-index: 9;
  position: relative;
}

.lv-header-nav-middle {
  padding-left: 5px;
  padding-right: 5px;
}

.lv-header-shopping-btn.lv-header-shopping-btn-2 {
  color: var(--clr-theme-primary);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area-2 {
    padding: 0 25px;
  }
}

.lv-header-shopping-btn.lv-header-shopping-btn-3 i {
  color: var(--clr-common-black);
}

.lv-header-nav-3-space {
  padding: 10px 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-header-nav-3-space {
    padding: 10px 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-header-nav-3-space {
    padding: 10px 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-header-nav-3-space {
    padding: 10px 30px;
  }
}
@media (max-width: 767px) {
  .lv-header-nav-3-space {
    padding: 10px 15px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.lv-header-shopping-btn.lv-header-shopping-btn-3 {
  margin-top: 13px;
}

.lv-header-bar-3-space {
  transform: translateY(-15px);
  display: inline-block;
  padding-left: 40px;
  padding-bottom: 2px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-header-bar-3-space {
    padding-bottom: 1px;
  }
}
@media (max-width: 767px) {
  .lv-header-bar-3-space {
    padding-left: 25px;
  }
}

div.lv-has-right-position {
  left: auto;
  right: -539px;
}

.lv-has-right-position.header-sidebar-visible {
  right: 0;
  left: auto;
}

div.lv-has-right-position.header-sidebar-visible {
  right: 0;
  left: auto;
}

ul.submenu li a svg {
  display: none;
}

.pata-dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #cdcdcd;
  border-radius: 50%;
}

/**
********************************************
************* 4.Slider
********************************************
*/
.lv-single-slider {
  background-color: var(--clr-body-grey);
  position: relative;
}
.lv-single-slider > * {
  position: relative;
  z-index: 3;
}
.lv-single-slider::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.3;
  content: "";
  background-color: var(--clr-common-black);
}
.lv-single-slider-title {
  font-size: 60px;
  color: var(--clr-common-white);
  text-transform: uppercase;
  margin-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-single-slider-title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-single-slider-title {
    font-size: 57px;
  }
}
@media (max-width: 767px) {
  .lv-single-slider-title {
    font-size: 34px;
    line-height: 1.6;
  }
}
.lv-single-slider-desc {
  font-size: 20px;
  color: var(--clr-common-white);
  margin-bottom: 45px;
}
.lv-single-slider-link {
  position: relative;
  z-index: 3;
  line-height: 1;
}

.lv-slider-height {
  min-height: 700px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-slider-height {
    min-height: 850px;
  }
}
@media (max-width: 767px) {
  .lv-slider-height {
    min-height: 490px;
  }
}
.lv-slider-title {
  font-size: 90px;
  color: var(--clr-theme-1);
}
.lv-slider-pagination {
  position: absolute;
  right: -25px;
  top: 490px;
  transform: rotate(90deg);
  z-index: 4;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-slider-pagination {
    display: none;
  }
}
.lv-slider-pagination .swiper-pagination-bullet {
  width: auto;
  height: auto;
  background-color: transparent;
  opacity: 1;
}
.lv-slider-pagination span {
  font-size: 12px;
  color: var(--clr-common-white);
  font-family: "Prata", serif;
  font-size: 12px;
  position: relative;
  transform: rotate(-90deg);
  font-weight: 400;
  display: inline-block;
  margin: 0 20px;
}
.lv-slider-pagination span::after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  display: inline-block;
  background: #fff;
  left: 50%;
  top: -13px;
  border-radius: 50%;
  transform: translateX(-50%);
}
.lv-slider-pagination span:first-child::after {
  display: none;
}
.lv-slider-pagination span.swiper-pagination-bullet-active {
  font-size: 18px;
}
.lv-slider-pagination .swiper-button-prev,
.lv-slider-pagination .swiper-button-next {
  height: auto;
}
.lv-slider-pagination .swiper-button-prev::after,
.lv-slider-pagination .swiper-button-next::after {
  display: none;
}
.lv-slider-pagination .swiper-button-prev img,
.lv-slider-pagination .swiper-button-next img {
  width: 17px;
}
.lv-slider-pagination .swiper-button-next img {
  transform: rotate(-90deg) translateX(57px) translateY(71px);
}
.lv-slider-pagination .swiper-button-prev img {
  transform: rotate(-90deg) translateX(57px) translateY(-71px);
}

.header-transparent {
  position: absolute;
  left: 0;
  z-index: 3;
  top: 0;
  width: 100%;
}

.lv-slider-lang.lv-slider-lang-pos {
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: 3;
}
.lv-slider-lang.lv-slider-lang-pos a {
  font-size: 14px;
  padding-left: 28px;
  color: var(--clr-common-white);
  position: relative;
}
.lv-slider-lang.lv-slider-lang-pos a:not(:first-child)::after {
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--clr-common-white);
}

@media (max-width: 767px) {
  .lv-single-slider-content.text-center.pt-125 {
    padding-top: 0;
  }
}

@media (max-width: 575px) {
  .lv-slider-lang.lv-slider-lang-pos {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .lv-slider-lang.lv-slider-lang-pos a {
    padding-left: 14px;
    padding-right: 14px;
  }

  .lv-slider-lang.lv-slider-lang-pos a:not(:first-child)::after {
    left: -6px;
  }
}
/**
********************************************
************* 5.About
********************************************
*/
.lv-about-pata-img-wrap .lv-about-pata-parent {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -90px;
  left: 30px;
  width: 15px;
  height: 10px;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  animation-duration: 12s;
  z-index: -1;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(1) {
  animation-name: pataAboutTop1;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(2) {
  animation-name: pataAboutTop2;
  animation-delay: 0.5s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(3) {
  animation-name: pataAboutTop1;
  animation-delay: 0.7s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(4) {
  animation-name: pataAboutTop1;
  animation-delay: 1.3s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(5) {
  animation-name: pataAboutTop3;
  animation-delay: 1.5s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(6) {
  animation-name: pataAboutTop1;
  animation-delay: 2s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(7) {
  animation-name: pataAboutTop2;
  animation-delay: 2.6s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(8) {
  animation-name: pataAboutTop4;
  animation-delay: 6.24s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(9) {
  animation-name: pataAboutTop5;
  animation-delay: 5.7s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(10) {
  animation-name: pataAboutTop6;
  animation-delay: 7.3s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(11) {
  animation-name: pataAboutTop1;
  animation-delay: 3.9s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(12) {
  animation-name: pataAboutTop2;
  animation-delay: 6.5s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(13) {
  animation-name: pataAboutTop1;
  animation-delay: 4.7s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(14) {
  animation-name: pataAboutTop1;
  animation-delay: 6.3s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(15) {
  animation-name: pataAboutTop3;
  animation-delay: 5.5s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(16) {
  animation-name: pataAboutTop1;
  animation-delay: 6.3s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(17) {
  animation-name: pataAboutTop2;
  animation-duration: 4.6s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(18) {
  animation-name: pataAboutTop4;
  animation-delay: 4.24s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(19) {
  animation-name: pataAboutTop5;
  animation-delay: 2.7s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-child(20) {
  animation-name: pataAboutTop6;
  animation-delay: 6.3s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent img {
  max-width: 100%;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n + 1)
  .lv-about-pata-rotate {
  animation-delay: -0.879s;
  animation-duration: 3.5s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent .lv-about-pata-rotate {
  animation: driftyRotate 1s infinite both ease-in-out;
  perspective: 1000;
}
.lv-about-pata-img-wrap .lv-about-pata-parent .lv-about-pata-rotate .askew {
  transform: skewY(10deg);
  display: block;
  width: 2rem;
  height: 2rem;
  animation: drifty 1s infinite alternate both ease-in-out;
  animation-duration: 1s;
  animation-delay: 0s;
  perspective: 1000;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n)
  .lv-about-pata-rotate {
  animation-duration: 2s;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n + 1)
  .lv-about-pata-rotate {
  animation-duration: 2.3s;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n + 2)
  .lv-about-pata-rotate {
  animation-duration: 1.1s;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n + 3)
  .lv-about-pata-rotate {
  animation-duration: 0.75s;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n + 4)
  .lv-about-pata-rotate {
  animation-duration: 4.3s;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n + 5)
  .lv-about-pata-rotate {
  animation-duration: 3.05s;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n + 6)
  .lv-about-pata-rotate {
  animation-duration: 2.76s;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n + 7)
  .lv-about-pata-rotate {
  animation-duration: 7.6s;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n + 8)
  .lv-about-pata-rotate {
  animation-duration: 1.78s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-of-type(7n) .askew {
  animation-delay: -0.6s;
  animation-duration: 2.25s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-of-type(7n + 1) .askew {
  animation-delay: -0.879s;
  animation-duration: 3.5s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-of-type(7n + 2) .askew {
  animation-delay: -0.11s;
  animation-duration: 1.95s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-of-type(7n + 3) .askew {
  animation-delay: -0.246s;
  animation-duration: 0.85s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-of-type(7n + 4) .askew {
  animation-delay: -0.43s;
  animation-duration: 2.5s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-of-type(7n + 5) .askew {
  animation-delay: -0.56s;
  animation-duration: 1.75s;
}
.lv-about-pata-img-wrap .lv-about-pata-parent:nth-of-type(7n + 6) .askew {
  animation-delay: -0.76s;
  animation-duration: 1.5s;
}
.lv-about-pata-img-wrap
  .lv-about-pata-parent:nth-of-type(9n + 1)
  .lv-about-pata-rotate {
  animation-duration: 2.3s;
}
.lv-about-img-left {
  position: relative;
}
@media (max-width: 767px) {
  .lv-about-img-left {
    padding-left: 50px;
    padding-top: 40px;
  }
}
/* .lv-about-img-left img {
  max-width: 420px;
} */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-img-left img {
    transform: translateY(70px);
  }
}
@media (max-width: 767px) {
  .lv-about-img-left img {
    max-width: 100%;
  }
}
.lv-about-img-1-1 {
  min-height: 700px;
}
@media (max-width: 767px) {
  .lv-about-img-1-1 {
    min-height: 400px;
  }
}
.lv-about-love-quote {
  position: absolute;
  top: 59px;
  left: -48px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .lv-about-love-quote {
    left: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-about-love-quote {
    top: 49px;
    left: 64px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-love-quote {
    left: 70px;
    top: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-about-love-quote {
    left: 90px;
    top: 40px;
  }
}
@media (max-width: 767px) {
  .lv-about-love-quote {
    /* top: 24px; */
    left: 0;
  }
}
.lv-about-love-flower {
  position: absolute;
  left: -238px;
  top: -327px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .lv-about-love-flower {
    left: -160px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-about-love-flower {
    left: -96px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-love-flower {
    left: -70px;
    top: -267px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-about-love-flower {
    left: -91px;
  }
}
@media (max-width: 767px) {
  .lv-about-love-flower {
    left: -40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-love-flower svg {
    max-width: 510px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-about-love-flower svg {
    max-width: 630px;
  }
}
@media (max-width: 767px) {
  .lv-about-love-flower svg {
    max-width: 367px;
    margin-top: 80px;
  }
}
.lv-about-blue-point-right {
  overflow: hidden;
  display: table;
  margin-top: -5px;
}
.lv-about-blue-point-wrap {
  border-top: 1px solid var(--clr-border-light);
  padding-top: 27px;
  border-bottom: 1px solid var(--clr-border-light);
  padding-bottom: 21px;
}
.lv-about-blue-point-title {
  font-size: 16px;
  line-height: 26px;
  font-family: "Muli", sans-serif;
  color: var(--clr-body-text);
  margin-bottom: 0;
  font-weight: 300;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-blue-point-title br {
    display: none;
  }
}
.lv-about-blue-point-title span {
  font-size: 28px;
  font-family: "Prata", serif;
  color: var(--clr-common-black);
  font-weight: 400;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-blue-point-title span {
    font-size: 23px;
  }
}
.lv-about-play-btn {
  float: left;
  padding-right: 20px;
}
.lv-about-play-btn-label {
  line-height: 20px;
  color: var(--clr-body-text);
  display: table-cell;
  vertical-align: middle;
}

.z-4 {
  position: relative;
  z-index: 4;
}

.wide-section-space {
  position: relative;
  z-index: 4;
  margin-top: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .wide-section-space {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .lv-about-img-left.pl-15.p-rel {
    padding-left: 77px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-about-img-left.pl-15.p-rel {
    padding-left: 111px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-img-left.pl-15.p-rel {
    padding-left: 69px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-about-img-left.pl-15.p-rel {
    padding-left: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-content-right {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-blue-point-right.pl-35 {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .lv-about-blue-point-right.pl-35 {
    padding-left: 0;
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .about-area-fix {
    overflow: hidden;
    padding-top: 210px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-img-wrap-1-1.pr-30 {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-about-content-right.lv-about-content-right-1-1 {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-section-title.lv-section-title-1-1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-experience-left-1-1.pr-70 {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-counter-box.pl-20 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-about-img-wrap-1-1.pr-30 {
    padding-right: 0;
  }
}

.lv-product-box-img-1-6.bg-default {
  background-color: transparent;
}

/**
********************************************
************* 6.Section Title
********************************************
*/
.lv-section-title {
  font-size: 50px;
  line-height: 1.3;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-section-title br {
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-section-title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-section-title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .lv-section-title {
    font-size: 30px;
  }
}
.lv-section-title.mb-10 {
  margin-bottom: 10px;
}
.lv-section-title.mb-45 {
  margin-bottom: 45px;
}

.lv-section-subtitle-2 {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--clr-theme-primary);
}
.lv-section-title-2 {
  font-size: 30px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .lv-section-title-2 {
    font-size: 25px;
    line-height: 1.3;
  }
}
.lv-section-title-path-2 svg {
  max-width: 110px;
  height: auto;
}

/**
********************************************
************* 7.Banner
********************************************
*/
.lv-weiding-video-banner-box {
  background-color: var(--clr-common-black);
}
.lv-weiding-video-banner-box .lv-weiding-video-banner-pos {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lv-weiding-video-banner-link {
  width: 184px;
  height: 184px;
  display: inline-block;
  line-height: 184px;
  text-align: center;
  font-size: 23px;
  border-radius: 50%;
  color: var(--clr-common-white);
}
.lv-weiding-video-banner-link:hover {
  color: var(--clr-common-white);
}
.lv-weiding-video-banner-link svg {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  fill: none;
  stroke: var(--clr-common-white);
  -webkit-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03),
    stroke 10ms ease-out;
  -o-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03),
    stroke 10ms ease-out;
  transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03), stroke 10ms ease-out;
  transition-duration: 0.8s, 10ms;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  -webkit-transition-duration: 1.1s;
  outline: 1px solid #ffffff7d;
  border-radius: 50%;
  outline-offset: -10px;
  -o-transition-duration: 1.1s;
  transition-duration: 1.1s;
}
.lv-weiding-video-banner-link svg circle {
  fill: none;
  stroke: var(--clr-common-white);
  stroke-dasharray: 950;
  stroke-dashoffset: 950;
  stroke-width: 1;
  -webkit-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03);
  -o-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03);
  transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03);
  transition-duration: 0.8s;
  -webkit-transition-duration: 1.1s;
  -o-transition-duration: 1.1s;
  transition-duration: 1.1s;
}
.lv-weiding-video-banner-link:hover svg {
  -webkit-transform: rotate(-45deg) translateZ(0);
  transform: rotate(-45deg) translateZ(0);
}
.lv-weiding-video-banner-link:hover svg circle {
  stroke-dashoffset: 10;
}
.lv-weiding-video-banner-height {
  min-height: 580px;
}
@media (max-width: 767px) {
  .lv-weiding-video-banner-height {
    min-height: 450px;
  }
}
.lv-weiding-video-banner-path {
  position: absolute;
  right: -207px;
  top: 25px;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-weiding-video-banner-path {
    right: -90px;
  }
}

.weidding-video-banner-space {
  margin-top: -290px;
  z-index: 5;
  position: relative;
}

.lv-banner-content-inner-3 {
  overflow: hidden;
}
.lv-banner-title-3 {
  font-size: 130px;
  line-height: 1.1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-banner-title-3 {
    font-size: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-banner-title-3 {
    font-size: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-banner-title-3 {
    font-size: 80px;
  }
}
@media (max-width: 767px) {
  .lv-banner-title-3 {
    font-size: 44px;
  }
}
.lv-banner-img-3 {
  width: 350px;
  height: 450px;
  background-color: var(--clr-theme-primary);
  float: left;
}
@media (max-width: 767px) {
  .lv-banner-img-3 {
    float: none;
  }
}
.lv-banner-link-3 {
  display: inline-block;
  border: 1px solid var(--clr-common-black);
  height: 50px;
  line-height: 48px;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--clr-common-black);
  padding: 0 40px;
}
.lv-banner-link-3:hover {
  background-color: var(--clr-common-black);
  color: var(--clr-common-white);
}

.pr-285 {
  padding-right: 285px;
}

.pt-275 {
  padding-top: 275px;
}

.pr-235 {
  padding-right: 235px;
}

.lv-banner-extra-team-member-single-thumb-3-1 {
  width: 350px;
  height: 500px;
  position: absolute;
  top: 235px;
  left: 0;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .lv-banner-extra-team-member-single-thumb-3-1 {
    width: 290px;
    height: 360px;
    top: 185px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .lv-banner-extra-team-member-single-thumb-3-1 {
    width: 200px;
    height: 280px;
    top: 135px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-banner-extra-team-member-single-thumb-3-1 {
    width: 220px;
    height: 250px;
    top: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-banner-extra-team-member-single-thumb-3-1 {
    width: 220px;
    height: 240px;
    top: 11px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-banner-extra-team-member-single-thumb-3-1 {
    width: 190px;
    height: 230px;
    top: 0;
  }
}
@media (max-width: 767px) {
  .lv-banner-extra-team-member-single-thumb-3-1 {
    position: static;
    margin: 30px 15px;
    height: 396px;
    width: calc(100% - 30px);
  }
}
.lv-banner-extra-team-member-single-thumb-3-2 {
  width: 400px;
  height: 550px;
  position: absolute;
  right: 0;
  top: 8px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .lv-banner-extra-team-member-single-thumb-3-2 {
    width: 310px;
    height: 420px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-banner-extra-team-member-single-thumb-3-2 {
    width: 280px;
    height: 360px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-banner-extra-team-member-single-thumb-3-2 {
    width: 240px;
    height: 250px;
  }
}
@media (max-width: 767px) {
  .lv-banner-extra-team-member-single-thumb-3-2 {
    position: static;
    margin: 0 15px;
    max-height: 370px;
    background-position: center center;
    width: calc(100% - 30px);
    margin-bottom: 30px;
  }
}
.lv-banner-extra-team-member-single-thumb-3-3 {
  width: 250px;
  height: 300px;
  position: absolute;
  right: 225px;
  bottom: 130px;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .lv-banner-extra-team-member-single-thumb-3-3 {
    right: 145px;
    bottom: 90px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .lv-banner-extra-team-member-single-thumb-3-3 {
    right: 105px;
    bottom: 90px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-banner-extra-team-member-single-thumb-3-3 {
    right: 65px;
    bottom: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-banner-extra-team-member-single-thumb-3-3 {
    right: 0;
    bottom: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-banner-extra-team-member-single-thumb-3-3 {
    width: 210px;
    height: 250px;
    right: 0;
    bottom: 0;
  }
}
@media (max-width: 767px) {
  .lv-banner-extra-team-member-single-thumb-3-3 {
    position: static;
    margin: 0 15px;
    width: calc(100% - 30px);
    background-position: center top;
    height: 370px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-banner-content-inner-3.pl-50 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-banner-content-text-3.pr-235 {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-banner-title-3.pl-105 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-banner-img-3.mr-70 {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .lv-banner-content-3.pt-275 {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .lv-banner-extra-team-member-wrap-3 {
    display: flex;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .lv-banner-extra-team-member-wrap-3 > * {
    flex: 0 0 44%;
    margin-top: 0px;
    height: 260px;
  }
}

.lv-banner-content-3 {
  position: relative;
  z-index: 9;
}

@media (max-width: 767px) {
  .lv-banner-extra-team-member-single-thumb-3-3 {
    margin-top: 70px;
  }
}

/**
********************************************
************* 8.Service
********************************************
*/
.lv-service-box {
  padding: 0 20px;
  position: relative;
}
.lv-service-box::after {
  width: 1px;
  height: 220px;
  background-color: var(--clr-border-light);
  right: -14px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  content: "";
}
@media (max-width: 767px) {
  .lv-service-box::after {
    display: none;
  }
}
.lv-service-box.no-after::after {
  display: none;
}
.lv-service-box-icon {
  margin-bottom: 25px;
}
.lv-service-box-icon i {
  display: inline-block;
  font-size: 54px;
}
.lv-service-box-content-title {
  font-size: 22px;
  margin-bottom: 18px;
}
.lv-service-box-content-title:hover {
  color: var(--clr-common-black);
}
.lv-service-box:hover .lv-service-box-icon i {
  -webkit-animation: blo-danceBottom 400ms ease-in-out;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-service-area .row > :nth-child(2) .lv-service-box::after {
    opacity: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .gallery-grid .lv-section-title-wrap p br {
    display: none;
  }
}

.no-after::after {
  display: none;
}

.lv-service-box-space-1-2 {
  padding: 0 0;
  padding-right: 10px;
}

/**
********************************************
************* 9.Gallery
********************************************
*/
.lv-single-featured-gallery:hover .lv-single-featured-gallery-img {
  clip-path: inset(13px 13px 13px 13px);
}
.lv-single-featured-gallery-img {
  background-color: var(--clr-theme-primary);
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  overflow: hidden;
}
.lv-single-featured-gallery-img-1 {
  height: 730px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-single-featured-gallery-img-1 {
    height: 550px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-single-featured-gallery-img-1 {
    height: 500px;
  }
}
@media (max-width: 767px) {
  .lv-single-featured-gallery-img-1 {
    height: 440px;
    margin-top: 1%;
  }
}
.lv-single-featured-gallery-img-2 {
  height: 400px;
}
.lv-single-featured-gallery-img-3 {
  height: 400px;
}
@media (max-width: 767px) {
  .lv-single-featured-gallery-img-3 {
    height: 400px;
  }
}
.lv-single-featured-gallery-img-4 {
  height: 650px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-single-featured-gallery-img-4 {
    height: 550px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-single-featured-gallery-img-4 {
    height: 500px;
  }
}
@media (max-width: 767px) {
  .lv-single-featured-gallery-img-4 {
    height: 450px;
  }
}
.lv-single-featured-gallery-subtitle {
  font-family: "Muli", sans-serif;
  font-size: 12px;
  color: var(--clr-body-text);
  text-transform: uppercase;
  margin-bottom: 16px;
}
.lv-single-featured-gallery-title {
  margin-bottom: 0;
}
.lv-single-featured-gallery-explore-btn-link {
  position: relative;
  display: table;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #707070;
  text-align: center;
}
.lv-single-featured-gallery-explore-btn-link svg.explore-svg-cirlce {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  fill: none;
  stroke: var(--clr-common-white);
  -webkit-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03),
    stroke 10ms ease-out;
  -o-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03),
    stroke 10ms ease-out;
  transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03), stroke 10ms ease-out;
  transition-duration: 0.8s, 10ms;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  -webkit-transition-duration: 1.1s;
  outline: 1px solid #ffffff7d;
  border-radius: 50%;
  outline-offset: -10px;
  -o-transition-duration: 1.1s;
  transition-duration: 1.1s;
}
.lv-single-featured-gallery-explore-btn-link svg.explore-svg-cirlce circle {
  fill: none;
  stroke: var(--clr-common-white);
  stroke-dasharray: 950;
  stroke-dashoffset: 950;
  stroke-width: 1;
  -webkit-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03);
  -o-transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03);
  transition: all 0.8s cubic-bezier(0.45, 0, 0.14, 1.03);
  transition-duration: 0.8s;
  -webkit-transition-duration: 1.1s;
  -o-transition-duration: 1.1s;
  transition-duration: 1.1s;
}
.lv-single-featured-gallery-explore-btn-link:hover svg.explore-svg-cirlce {
  -webkit-transform: rotate(-45deg) translateZ(0);
  transform: rotate(-45deg) translateZ(0);
}
.lv-single-featured-gallery-explore-btn-link:hover
  svg.explore-svg-cirlce
  circle {
  stroke-dashoffset: 10;
}
.lv-single-featured-gallery-explore-btn-link span {
  line-height: 20px;
  font-size: 14px;
  text-transform: uppercase;
  display: table-cell;
  text-align: center;
  color: var(--clr-common-black);
  vertical-align: middle;
}
.lv-single-featured-gallery-explore-btn-link svg {
  transition: all 0.3s;
}
.lv-single-featured-gallery-explore-btn-link svg.explore-errow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -41px;
  transition: all 0.3s;
}
.lv-single-featured-gallery-explore-btn-link:hover svg.explore-errow {
  right: -61px;
}

.lv-featured-gallery-overlay {
  position: relative;
}
.lv-featured-gallery-overlay .img-1 img {
  max-width: 320px;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .lv-featured-gallery-overlay .img-1 img {
    max-width: 170px;
  }
}
/* .lv-featured-gallery-overlay-height {
  height: 859px;
} */
@media (max-width: 767px) {
  .lv-featured-gallery-overlay-height {
    height: 919px;
  }
}
.lv-featured-gallery-overlay-pos {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.lv-featured-gallery-content-2-wrap {
  display: flex;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  left: 100%;
  padding: 10px;
  top: 100%;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  flex-direction: column;
  justify-content: center;
}
.lv-featured-gallery-content-subtitle-2 {
  font-size: 12px;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.lv-featured-gallery-content-title-2 {
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .lv-featured-gallery-content-title-2 {
    font-size: 14px;
    line-height: 1.5;
  }
}
.lv-featured-gallery-item-single-2 {
  display: table;
  width: 100%;
}
.lv-featured-gallery-paths-2 span:nth-child(1) {
  width: 0;
  height: 1px;
  background: var(--clr-common-black);
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 20px;
}
.lv-featured-gallery-paths-2 span:nth-child(2) {
  height: 0;
  width: 1px;
  background: var(--clr-common-black);
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 20px;
}
.lv-featured-gallery-paths-2 span:nth-child(3) {
  width: 0;
  height: 1px;
  background: var(--clr-common-black);
  display: inline-block;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.lv-featured-gallery-paths-2 span:nth-child(4) {
  height: 0;
  width: 1px;
  background: var(--clr-common-black);
  display: inline-block;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.lv-featured-gallery-shape-img-group {
  position: absolute;
  left: 0;
  bottom: 200px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-featured-gallery-shape-img-group {
    bottom: 0;
  }
}
.lv-featured-gallery-shape-img-group img {
  max-width: 164px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gallery-grid.row.g-70 {
    --bs-gutter-x: 55px;
  }
}

.lv-featured-gallery-height-1 {
  min-height: 450px;
}
@media (max-width: 767px) {
  .lv-featured-gallery-height-1 {
    min-height: 350px;
  }
}
.lv-featured-gallery-height-2 {
  min-height: 300px;
}
.lv-featured-gallery-height-3 {
  min-height: 380px;
}
@media (max-width: 767px) {
  .lv-featured-gallery-height-3 {
    min-height: 350px;
  }
}
.lv-featured-gallery-height-4 {
  min-height: 370px;
}
@media (max-width: 767px) {
  .lv-featured-gallery-height-4 {
    min-height: 330px;
  }
}
.lv-featured-gallery-height-5 {
  min-height: 450px;
}
@media (max-width: 767px) {
  .lv-featured-gallery-height-5 {
    min-height: 350px;
  }
}
.lv-featured-gallery-height-6 {
  min-height: 300px;
}

.p-30 {
  padding: 30px;
}

.lv-featured-gallery-item-height-2 {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  position: absolute;
  left: 20px;
  top: 20px;
  overflow: hidden;
}

.lv-featured-gallery-item-height-2:hover
  .lv-featured-gallery-paths-2
  span:nth-child(1),
.lv-featured-gallery-item-height-2:hover
  .lv-featured-gallery-paths-2
  span:nth-child(3) {
  width: 34.6px;
}
.lv-featured-gallery-item-height-2:hover
  .lv-featured-gallery-paths-2
  span:nth-child(2),
.lv-featured-gallery-item-height-2:hover
  .lv-featured-gallery-paths-2
  span:nth-child(4) {
  height: 34.6px;
}

.lv-horizontal-test-line-path-2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 104px;
  display: inline-block;
  width: 1px;
  background: var(--clr-common-black);
  top: -13px;
}

.lv-message-date-box-2 .swiper-slide {
  padding-top: 2px;
}

/**
********************************************
************* 10.Counter
********************************************
*/
.lv-counter-box {
  position: relative;
  display: inline-block;
}
.lv-counter-box-num {
  font-size: 90px;
  margin-bottom: 0;
  color: var(--clr-body-grey-soft);
}
.lv-counter-box-num span {
  font-family: "Prata", serif;
}
.lv-counter-box-num-link {
  font-size: 14px;
  position: absolute;
  bottom: 28px;
  text-transform: uppercase;
  right: -66px;
  color: var(--clr-common-black);
  height: 30px;
  display: inline-block;
  background: var(--clr-common-white);
}
.lv-counter-box-num-link:hover {
  color: var(--clr-common-black);
}

@media (max-width: 767px) {
  .lv-counter-box.pl-20 {
    padding-left: 0;
  }
}

/**
********************************************
************* 11.Testimonial
********************************************
*/
.testimonial-bg-settings {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.testimonial-area-spacing {
  margin-top: -37px;
}

.lv-testimonial-img {
  width: 130px;
  background: var(--clr-theme-primary);
  border-radius: 50%;
  overflow: hidden;
  height: 130px;
  margin: 0 auto;
  margin-bottom: 25px;
}
.lv-testimonial-img img {
  object-fit: cover;
  object-position: center;
}
.lv-testimonial-box {
  padding: 0 40px;
  padding-bottom: 55px;
  text-align: center;
}
@media (max-width: 767px) {
  .lv-testimonial-box {
    /* padding: 0; */
  }
}
.lv-testimonial-bg-img-pos {
  max-width: 224.17px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -9px;
}
.lv-testimonial-content {
  position: relative;
}
.lv-testimonial-author-name {
  font-size: 24px;
  margin-bottom: 0;
}
.lv-testimonial-author-date {
  font-size: 14px;
  font-weight: 300;
}
.lv-testimonial-pagination-nav-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lv-testimonial-pagination-nav-wrap .testimonial-paginations-2 {
  display: inline-block;
}
.lv-testimonial-pagination-nav-wrap .swiper-pagination-bullet {
  width: auto;
  display: inline-block;
  height: auto;
  background: none;
  border-radius: 0;
  opacity: 1;
  font-size: 12px;
  margin: 0 8px;
  font-family: "Prata", serif;
  position: relative;
}
.lv-testimonial-pagination-nav-wrap .swiper-pagination-bullet::after {
  content: "";
  position: absolute;
}
.lv-testimonial-pagination-nav-wrap .swiper-pagination-bullet-active {
  font-size: 18px;
  color: var(--clr-common-black);
}
.lv-testimonial-pagination-nav-wrap .swiper-button-next-2,
.lv-testimonial-pagination-nav-wrap .swiper-button-prev-2 {
  display: inline-block;
  line-height: 1;
  transform: translateY(-2px);
}
.lv-testimonial-pagination-nav-wrap .swiper-button-next-2 img,
.lv-testimonial-pagination-nav-wrap .swiper-button-prev-2 img {
  max-width: 50.55px;
}

.testimonial-shape-path-image-wrap .testimonial-shape-path-image {
  position: absolute;
  right: 30px;
  bottom: -50px;
  max-width: 169.63px;
  animation-name: toTopFlower;
  animation-duration: 3s;
}
@media (max-width: 767px) {
  .testimonial-shape-path-image-wrap .testimonial-shape-path-image {
    bottom: -100px;
    max-width: 99.63px;
  }
}

.lv-common-pagination-next-nav {
  display: inline-block;
  line-height: 1;
  transform: translateY(-2px);
}

.lv-common-pagination-dots .swiper-pagination-bullet {
  display: inline-block;
}

.lv-common-pagination-prev-nav {
  display: inline-block;
  line-height: 1;
  transform: translateY(-2px);
}

.lv-common-pagination-prev-nav img {
  max-width: 50.55px;
}

.lv-common-pagination-next-nav img {
  max-width: 50.55px;
}

.lv-common-pagination > * {
  cursor: pointer;
}

/**
********************************************
************* 12.Photography
********************************************
*/
.lv-photography-img-1-1 {
  height: 400px;
}
.lv-photography-img-1-2 {
  height: 500px;
}
.lv-photography-member-designation-link {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
}
.lv-photography-member-designation-link:hover {
  color: var(--clr-common-black);
}
.lv-photography-member-name {
  font-size: 20px;
  margin-bottom: 4px;
}
.lv-photography-member-name:hover {
  color: var(--clr-common-black);
}
.lv-photography-member-content {
  padding: 16px 0;
}
.lv-photography-member-social-links a {
  font-size: 14px;
  display: inline-block;
  padding: 0 12px;
  position: relative;
}
.lv-photography-member-social-links a:hover {
  color: var(--clr-common-black);
}
.lv-photography-member-social-links a::after {
  position: absolute;
  left: -5px;
  top: 13px;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--clr-body-text);
}
.lv-photography-member-social-links a:first-child::after {
  display: none;
}
.lv-photography-swiper-gallery-single {
  background-color: var(--clr-theme-primary);
  height: 350px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-photography-swiper-gallery-single {
    height: 402px;
  }
}

.lv-photography-member-social-active .lv-photography-img {
  transform: scale(1);
  transition: all 0.3s;
}
.lv-photography-member-social-active .lv-photography-img:hover {
  transform: scale(1.1);
}

@media (min-width: 768px) and (max-width: 991px) {
  .lv-photography-wrap-1 .row > *:nth-child(2) {
    order: -3;
  }

  .lv-photography-gallery-swiper-carousel.pt-145 {
    padding-top: 164px;
  }
}
@media (max-width: 767px) {
  .lv-photography-gallery-swiper-carousel.pt-145 {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-photography-member-social-share-1-3 .lv-photography-img-1-2 {
    height: 350px;
  }
}

/**
********************************************
************* 13.Brand
********************************************
*/
.lv-brand-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-column-gap: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-brand-flex-wrap {
    justify-content: flex-start;
  }
}
.lv-brand-flex-single img {
  opacity: 0.25;
}
.lv-brand-flex-single:hover img {
  opacity: 0.7;
}
.lv-brand-title-2 {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0;
  position: relative;
}
@media (max-width: 767px) {
  .lv-brand-title-2 {
    line-height: 1.4;
  }
}

@media (max-width: 767px) {
  .lv-brand-flex-wrap > * {
    flex: 0 0 46%;
  }
}

.lv-brand-title-sudo-lines-2 span {
  width: 160px;
  height: 1px;
  display: inline-block;
  background-color: var(--clr-border-light);
  position: absolute;
}
.lv-brand-title-sudo-lines-2 span:nth-child(1) {
  right: calc(100% + 21px);
  top: 5px;
}
.lv-brand-title-sudo-lines-2 span:nth-child(2) {
  right: calc(100% + 21px);
  top: 12px;
}
.lv-brand-title-sudo-lines-2 span:nth-child(3) {
  left: calc(100% + 21px);
  top: 5px;
}
.lv-brand-title-sudo-lines-2 span:nth-child(4) {
  left: calc(100% + 21px);
  top: 12px;
}

/**
********************************************
************* 14.Blog
********************************************
*/
.lv-blog-box-img {
  height: 250px;
  transform: scale(1);
  transition: all 0.3s;
}
.lv-blog-box:hover .lv-blog-box-img {
  transform: scale(1.1);
}
.lv-blog-box-meta {
  padding-bottom: 5px;
}
.lv-blog-box-meta-date {
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
}
.lv-blog-box-meta-cat {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.lv-blog-box-meta-cat:hover {
  color: var(--clr-common-black);
}
.lv-blog-box-meta .lv-blog-box-meta-cat {
  position: relative;
  margin-left: 20px;
}
.lv-blog-box-meta .lv-blog-box-meta-cat::after {
  width: 4px;
  height: 4px;
  display: inline-block;
  background-color: var(--clr-body-grey);
  content: "";
  position: absolute;
  left: -15px;
  border-radius: 50%;
  top: 13px;
}
.lv-blog-box-title {
  font-size: 24px;
  line-height: 1.38;
  margin-bottom: 18px;
}
.lv-blog-box-title:hover {
  color: var(--clr-common-black);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-blog-box-title {
    font-size: 23px;
  }
}
.lv-blog-box-read-btn {
  display: inline-block;
  font-size: 12px;
  font-family: "Muli";
  text-transform: uppercase;
  color: var(--clr-common-black);
  position: relative;
}
.lv-blog-box-read-btn::before {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--clr-body-grey-2);
  content: "";
  width: 72px;
  height: 1px;
}
.lv-blog-box-read-btn::after {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--clr-common-black);
  content: "";
  width: 0;
  transition: all 0.3s;
  height: 1px;
}
.lv-blog-box-read-btn:hover {
  color: var(--clr-common-black);
}
.lv-blog-box-read-btn:hover::after {
  left: 0;
  right: auto;
  width: 72px;
}
.lv-blog-box-content {
  padding: 25px 0;
}
.lv-blog-thumb-1-8 {
  position: relative;
}
.lv-blog-content-1-8 {
  background: var(--clr-common-white);
  padding: 40px 40px;
  z-index: 1;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
@media (max-width: 767px) {
  .lv-blog-content-1-8 {
    padding: 30px 25px;
  }
}
.lv-blog-box-1-8 {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 30px 40px 0px rgba(0, 6, 69, 0.08);
  overflow: hidden;
}
.lv-blog-title-1-8 {
  font-size: 28px;
  line-height: 1.5;
  margin-bottom: 18px;
  color: #020163;
}
@media (max-width: 767px) {
  .lv-blog-title-1-8 {
    font-size: 19px;
    color: #020163;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .lv-blog-title-1-8 {
    font-size: 25px;
    color: #020163;
  }
}
.lv-blog-title-1-8:hover a {
  color: var(--clr-theme-primary);
}
.lv-blog__meta-1-8 ul li {
  display: inline-block;
  list-style: none;
  margin-right: 15px;
}
.lv-blog__meta-1-8 ul li a {
  font-size: 16px;
  line-height: 1;
  color: var(--clr-body-grey);
  text-transform: capitalize;
}
.lv-blog__meta-1-8 ul li a:hover {
  color: var(--clr-theme-primary);
}
.lv-blog__meta-1-8 ul li a:hover i {
  color: var(--clr-theme-primary);
}
.lv-blog__meta-1-8 ul li a i {
  margin-right: 5px;
  color: var(--clr-theme-primary);
}

.lv-blog-shape-wrap .lv-blog-shape-img-1 {
  max-width: 376px;
  position: absolute;
  top: 0;
  z-index: -1;
  left: 90px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-blog-shape-wrap .lv-blog-shape-img-1 {
    max-width: 266px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-blog-shape-wrap .lv-blog-shape-img-1 {
    max-width: 266px;
    left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-blog-shape-wrap .lv-blog-shape-img-1 {
    max-width: 206px;
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-blog-space-right-1-8 {
    margin-right: 0;
  }
}

blockquote {
  margin: 35px 0px !important;
  padding: 40px;
  color: #666;
  position: relative;
  background: #f6f6f6;
  font-style: normal;
  text-align: left;
  clear: both;
  font-weight: 400;
  box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
  border: 1px solid #f6f6f6;
}

blockquote::before {
  content: "";
  position: static;
  font-family: "Font Awesome 5 Pro";
  font-size: 32px;
  color: var(--clr-theme-primary);
  line-height: 1;
  margin-bottom: 18px;
  display: inline-block;
}

.post-text blockquote p {
  margin-bottom: 10px !important;
  line-height: 1.4;
  font-size: 20px;
  color: #070337;
  font-weight: 400;
}

blockquote cite {
  font-size: 18px;
  display: block;
  margin-top: 10px;
  color: #070337;
  font-style: inherit;
  font-weight: 600;
}

blockquote cite::before {
  content: "";
  font-size: 28px;
  color: #ff5421;
  padding-bottom: 0px;
  display: inline-block;
  background: var(--clr-theme-primary);
  height: 2px;
  width: 40px;
  font-weight: 400;
  text-align: center;
  top: -4px;
  margin-right: 10px;
  position: relative;
}

.blog__details__tag.tagcloud > p {
  font-weight: 500;
}

.lv-insta-banner-widget-img-6 {
  height: 500px;
  outline: 2px solid #fff;
  outline-offset: -20px;
}

/**
********************************************
************* 15.Instagram
********************************************
*/
.lv-instagram-icon {
  position: relative;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.lv-instagram-icon i {
  font-size: 29px;
  color: var(--clr-common-white);
}
.lv-instagram-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lv-instagram-img::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  content: "";
  background-color: var(--clr-common-black);
}
.lv-instagram-img-height {
  height: 298px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-instagram-img-height {
    height: 188px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-instagram-img-height {
    height: 178px;
  }
}
.lv-instagram-img-height-2 {
  height: 230px;
}
.lv-instagram-img:hover::after {
  opacity: 0.5;
  visibility: visible;
}
.lv-instagram-img:hover .lv-instagram-icon {
  opacity: 1;
  visibility: visible;
}
.lv-instagram-title-2 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.lv-instagram-subtitle-2 {
  color: var(--clr-body-grey);
}

.lv-instagram-up-down-gallery-2 > .row > *:nth-child(odd) {
  margin-top: 47px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-instagram-up-down-gallery-2 > .row > *:nth-child(odd) {
    margin-top: 0;
  }
}

.lv-has-instagram-overlay-bg-2 {
  position: relative;
}
.lv-has-instagram-overlay-bg-2 > * {
  position: relative;
  z-index: 3;
}
.lv-has-instagram-overlay-bg-2::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 270px;
  width: 100%;
  content: "";
  background-color: var(--clr-bg-theme-soft);
}

/**
********************************************
************* 16.Footer
********************************************
*/
.lv-footer-border-top {
  border-top: 1px solid var(--clr-border-light_2);
}
.lv-footer-title {
  font-size: 18px;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.lv-footer-social-link {
  padding-bottom: 8px;
}
.lv-footer-social-link a {
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 16px;
  position: relative;
}
.lv-footer-social-link a:last-child {
  margin-right: 0;
}
.lv-footer-social-link a:not(:first-child)::after {
  width: 4px;
  height: 4px;
  background: var(--clr-body-grey);
  display: inline-block;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: -12px;
  top: 13px;
}
.lv-footer-social-link a:hover {
  color: var(--clr-common-black);
}
.lv-footer-subscribe-form {
  position: relative;
}
.lv-footer-subscribe-form input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--clr-body-grey);
  font-size: 12px;
  color: var(--clr-body-grey);
  text-transform: uppercase;
}
.lv-footer-subscribe-form input::-webkit-input-placeholder {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--clr-body-grey);
}
.lv-footer-subscribe-form input:-moz-placeholder {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--clr-body-grey);
}
.lv-footer-subscribe-form input::-moz-placeholder {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--clr-body-grey);
}
.lv-footer-subscribe-form input:-ms-input-placeholder {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--clr-body-grey);
}
.lv-footer-subscribe-form input:focus {
  outline: none;
  border-bottom-color: var(--clr-common-black);
}
.lv-footer-subscribe-form button[type="submit"],
.lv-footer-subscribe-form input[type="submit"] {
  position: absolute;
  right: 0;
  bottom: -1px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .lv-footer-subscribe-form button[type="submit"],
  .lv-footer-subscribe-form input[type="submit"] {
    right: 20px;
  }
}
.lv-footer-link-logo {
  max-width: 128.37px;
}
.lv-footer-link-logo-wrap {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
}
.lv-footer-link-menu {
  font-size: 12px;
  font-weight: 300;
}
.lv-footer-touch-widget-links {
  padding-top: 8px;
}
.lv-footer-touch-widget-links a,
.lv-footer-touch-widget-links span {
  font-size: 12px;
  font-weight: 300;
  display: block;
  line-height: 1.9;
}
.lv-footer-copyright {
  padding: 13px 0;
  padding-top: 10px;
}
.lv-footer-copyright p {
  margin-bottom: 0;
  font-size: 12px;
  color: var(--clr-common-white);
  text-transform: uppercase;
}

.black-bg {
  background-color: var(--clr-common-black);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .footer-widget.text-center.pl-55.pr-55 {
    padding: 0;
  }
}
.lv-footer-widget-wrap .row > *:not(:last-child) .footer-widget {
  position: relative;
}
.lv-footer-widget-wrap .row > *:not(:last-child) .footer-widget::after {
  position: absolute;
  right: -5px;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 150px;
  background-color: var(--clr-border-light);
}
@media (max-width: 767px) {
  .lv-footer-widget-wrap .row > *:not(:last-child) .footer-widget::after {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-footer-widget-wrap .row > *:not(:last-child) .footer-widget::after {
    right: -38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-footer-widget-wrap .row > *:not(:last-child) .footer-widget::after {
    right: -44px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-footer-widget-wrap
    .row
    > *:not(:last-child):nth-child(2)
    .footer-widget::after {
    display: none;
  }
}

/**
********************************************
************* 17.Sidebar
********************************************
*/
.lv-header-sidebar-wrapper {
  padding: 0 25px;
}
.lv-header-sidebar-touch-widget .lv-footer-touch-widget-links a {
  font-size: 14px;
}
.lv-header-sidebar-touch-widget .lv-footer-title {
  margin-bottom: 6px;
}
.lv-header-sidebar-area {
  position: fixed;
  left: -140%;
  top: 0;
  overflow-y: auto;
  z-index: 10;
  transition: all 0.5s;
  z-index: 999;
  height: 100%;
  background: var(--clr-common-white);
  width: 400px;
}
.lv-header-sidebar-area.header-sidebar-visible {
  left: 0;
}
@media (max-width: 767px) {
  .lv-header-sidebar-area {
    width: 320px;
  }
}
.lv-header-sidebar-logo {
  text-align: center;
}
.lv-header-sidebar-logo a {
  max-width: 153.91px;
  display: inline-block;
}
.lv-header-sidebar-close-text {
  font-size: 14px;
  font-weight: 3;
  text-transform: uppercase;
  padding-right: 5px;
  display: inline-block;
  transform: translateY(1px);
}
.lv-header-sidebar-action a:hover {
  color: var(--clr-common-black);
}
.lv-header-sidebar-action a:hover svg path {
  fill: var(--clr-common-black);
}
.lv-header-sidebar-official-gallery-item {
  height: 100px;
  background-position: top center;
}
.lv-header-sidebar-widget .lv-footer-social-link a {
  font-size: 14px;
  margin-right: 20px;
}
.lv-header-sidebar-widget .lv-footer-social-link a::after {
  left: -14px;
}

.overlay {
  position: fixed;
  right: 0;
  top: 0;
  cursor: zoom-out;
  bottom: 0;
  left: 0;
  background: var(--clr-common-black);
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: all 0.3s;
}
.overlay.visible-overlay {
  opacity: 0.2;
  visibility: visible;
}

/**
********************************************
************* 18.Hero
********************************************
*/
.lv-hero-banner-img-2 {
  max-width: 795.06px;
  margin: 0 auto;
  margin-top: -43px;
  z-index: 3;
  position: relative;
}
@media (max-width: 767px) {
  .lv-hero-banner-img-2 {
    margin-top: 40px;
  }
}
.lv-hero-flower-2 {
  position: absolute;
  z-index: 4;
  bottom: -55px;
  left: 50%;
  transform: translateX(-50%);
}
.lv-hero-cloud {
  position: absolute;
  left: 0;
  bottom: 73px;
}

@media (max-width: 767px) {
  .lv-banner-content-text-3.pt-75 {
    padding-top: 40px;
  }
}

.lv-header-bar-2 {
  position: relative;
  z-index: 22;
}

/**
********************************************
************* 19.Message
********************************************
*/
.lv-message-date-box-2 {
  width: 197px;
  height: 197px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--clr-common-white);
  outline: 1px solid var(--clr-theme-primary);
  border-radius: 50%;
  outline-offset: -20px;
}
.lv-message-date-box-2-active {
  position: relative;
}
.lv-message-date-label-2 {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 11px;
}
.lv-message-date-value-2 {
  font-size: 18px;
  margin-bottom: 0;
}
.lv-message-content-title-2 {
  font-size: 30px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .lv-message-content-title-2 {
    font-size: 25px;
    line-height: 1.3;
  }
}
.lv-message-content-sign-2 {
  max-width: 124px;
  display: inline-block;
}

.lv-message-space-2 {
  position: relative;
  z-index: 4;
  margin-top: -80px;
}

.z-4 {
  z-index: 4;
}

@media (max-width: 767px) {
  .lv-message-content-box-2.pl-70.pr-70 {
    padding-left: 0;
    padding-right: 0;
  }
}

/**
********************************************
************* 20.Event
********************************************
*/
.lv-special-event-title {
  font-size: 24px;
  font-family: "monotype-corsiva", sans-serif;
  margin-bottom: 18px;
}
.lv-special-event-box-main-2 {
  position: relative;
}
.lv-special-event-box-main-2 .lv-special-event-date-frame {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.lv-special-event-box-main-2 .lv-special-event-date-frame img {
  width: 100%;
  height: 100%;
}
.lv-special-event-month-2 {
  font-size: 14px;
  color: var(--clr-common-black);
  text-transform: uppercase;
  margin-bottom: 7px;
  display: block;
}
.lv-special-event-date-frame-2 {
  left: 0;
  top: 0;
  position: absolute;
}
.lv-special-event-date-flex-box-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  padding-bottom: 2px;
}
.lv-special-event-date-year-2 p {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--clr-common-black);
}
.lv-special-event-item-2-1,
.lv-special-event-item-2-3 {
  font-size: 14px;
  color: var(--clr-common-black);
  display: inline-block;
  margin-bottom: 0;
  text-transform: uppercase;
  border-top: 0.5px solid var(--clr-theme-primary);
  border-bottom: 0.5px solid var(--clr-theme-primary);
}
.lv-special-event-item-2-2 {
  font-size: 30px;
  margin-bottom: 0;
  line-height: 1;
  display: inline-block;
}
.lv-special-event-address-2 p {
  color: var(--clr-common-black);
  display: block;
  margin-bottom: 6px;
}
.lv-special-event-address-2 p.item-last {
  margin-bottom: 25px;
}
.lv-special-event-middle-img-2 img {
  max-width: inherit;
  transform: translateX(-33px) translateY(-6px);
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .lv-special-event-middle-img-2 img {
    max-width: 100%;
    transform: translate(0);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-special-event-middle-img-2 img {
    transform: translateX(0);
  }
}
.lv-special-event-middle-img-bottom-2 {
  position: absolute;
  bottom: -33px;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-special-event-middle-img-bottom-2 {
    max-width: 160px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-special-event-middle-img-bottom-2 {
    bottom: -24px;
  }
}
.lv-special-event-middle-img-bottom-2 img {
  max-width: inherit;
}
@media (max-width: 767px) {
  .lv-special-event-middle-img-bottom-2 img {
    max-width: 160px;
  }
}

.bg-100 {
  background-size: 100% !important;
}

.lv-underline-button-2 {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--clr-common-black);
  position: relative;
  display: inline-block;
}
.lv-underline-button-2:hover {
  color: var(--clr-common-black);
}
.lv-underline-button-2::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--clr-common-black);
  content: "";
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-special-event-box-2.pr-52 {
    padding-right: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-special-event-box-2.pr-52 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-special-event-box-2.pl-52 {
    padding-left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-special-event-box-2.pl-52 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-special-event-box-content-2 {
    padding: 16px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-custom-event-order-2 {
    order: -1;
  }
}

@media (max-width: 767px) {
  .lv-special-event-box-content-2.bg-100 {
    background-size: contain !important;
  }
}

/**
********************************************
************* 21.Pricing
********************************************
*/
.lv-pricing-box-wrap-2 {
  padding: 10px;
  background: var(--clr-common-white);
}
.lv-pricing-box-content-2 {
  border: 1px solid #0000001a;
}
.lv-pricing-box-subtitle-2 {
  text-transform: uppercase;
  margin-bottom: 20px;
}
.lv-pricing-box-title-2 {
  font-size: 50px;
  margin-bottom: 3px;
}
.lv-pricing-box-title-2 sup {
  font-size: 20px;
  transform: translateY(-14px);
  display: inline-block;
}
.lv-pricing-box-heading-path-2 {
  line-height: 1;
}
.lv-pricing-box-list-2 ul li {
  font-size: 16px;
  color: var(--clr-common-black);
  margin-bottom: 5px;
  list-style: none;
}
.lv-pricing-box-action-btn-2 {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--clr-common-black);
  padding: 0 70px;
  background-color: var(--clr-border-light);
}
@media (max-width: 767px) {
  .lv-pricing-box-action-btn-2 {
    padding: 0 40px;
  }
}
.lv-pricing-box-action-btn-2:hover {
  background-color: var(--clr-common-black);
  color: var(--clr-common-white);
}
.lv-pricing-box-content-2 {
  padding: 42px 20px;
  padding-top: 45px;
  background-color: var(--clr-common-white);
}

/**
********************************************
************* 22.Breadcrumb
********************************************
*/
.has-breadcrumb-overlay {
  position: relative;
}
.has-breadcrumb-overlay::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  content: "";
  background-color: var(--clr-overlay-black);
}
.has-breadcrumb-overlay > * {
  position: relative;
  z-index: 3;
}

.lv-breadcrumb-title {
  margin-bottom: 0;
  line-height: 1.6;
  color: var(--clr-common-white);
  font-size: 60px;
}
@media (max-width: 767px) {
  .lv-breadcrumb-title {
    font-size: 48px;
    line-height: 1.4;
  }
}

.pt-205 {
  padding-top: 205px;
}

/**
********************************************
************* 23.Explore
********************************************
*/
.lv-explore-vision-box-content-1-1 {
  padding: 33px 0;
}
.lv-explore-vision-box-img-1-1 {
  height: 240px;
}
.lv-explore-vision-box-title-1-1 {
  font-size: 22px;
  margin-bottom: 17px;
}

/**
********************************************
************* 24.Experience
********************************************
*/
.has-experience-before-overlay-1-1 {
  position: relative;
}
.has-experience-before-overlay-1-1::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 120px);
  content: "";
  background-color: var(--clr-border-light_2);
}
.has-experience-before-overlay-1-1 > * {
  position: relative;
  z-index: 3;
}

.lv-experience-progress-label-1-1 {
  font-size: 12px;
  color: var(--clr-common-black);
  text-transform: uppercase;
  margin-bottom: 4px;
  display: inline-block;
}
.lv-experience-progress-single-1-1 {
  margin-bottom: 17px;
}
.lv-experience-progress-single-1-1 .progress {
  height: 1px;
  border-radius: 0;
  background-color: #ddd;
  overflow: visible;
}
.lv-experience-progress-single-1-1 .progress .progress-bar {
  overflow: visible;
  height: 3px;
  position: static;
  transform: translateY(-3px);
  background-color: var(--clr-common-black);
}
.lv-experience-progress-single-1-1 .progress .progress-bar span {
  position: absolute;
  right: 0;
  top: -28px;
  color: var(--clr-common-black);
  font-size: 12px;
}

.lv-experience-right-img-1-1 {
  min-height: 633px;
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .lv-experience-right-img-1-1 {
    padding-bottom: 40px;
    min-height: 500px;
  }
}

.lv-experience-play-btn-1-1 a {
  display: inline-block;
  width: 130px;
  line-height: 130px;
  height: 130px;
  background: var(--clr-common-white);
  text-align: center;
  border-radius: 50%;
  position: relative;
  font-size: 21px;
  color: var(--clr-common-black);
}
@media (max-width: 767px) {
  .lv-experience-play-btn-1-1 a {
    width: 90px;
    line-height: 90px;
    height: 90px;
  }
}
.lv-experience-play-btn-1-1 a::after {
  position: absolute;
  left: 15%;
  top: 15%;
  content: "";
  display: inline-block;
  width: 90px;
  height: 90px;
  border: 2px dashed var(--clr-common-black);
  border-radius: 50%;
  animation-name: border-round;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 0;
  animation-timing-function: linear;
}
@media (max-width: 767px) {
  .lv-experience-play-btn-1-1 a::after {
    left: 11%;
    top: 11%;
    width: 70px;
    height: 70px;
  }
}
.lv-experience-play-label-1-1 {
  font-size: 16px;
  color: #fff;
  clear: both;
  display: inline-block;
  padding-left: 20px;
  text-transform: uppercase;
  line-height: 1.2;
}

.lv-experience-space-reverce-1-1 {
  margin-left: -63px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lv-experience-space-reverce-1-1 {
    margin-left: 50px;
  }
}
@media (max-width: 767px) {
  .lv-experience-space-reverce-1-1 {
    margin-left: 20px;
  }
}

.mt-8 {
  margin-top: 8px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-experience-left-1-1.pr-70 {
    padding-right: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-experience-left-1-1.pr-70 {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .lv-experience-left-1-1 p br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-experience-right-1-1.pl-70 {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-experience-right-1-1.pl-70 {
    padding-left: 0;
  }
}

.lv-about-pata-img-wrap.lv-about-body-pata .lv-about-pata-parent {
  position: fixed;
}

/**
********************************************
************* 25.Portfolio
********************************************
*/
.lv-portfolio-box-1-3 .lv-portfolio-link-1-3 {
  position: absolute;
  z-index: 3;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lv-portfolio-box-1-3:hover .lv-portfolio-link-1-3 {
  opacity: 1;
  visibility: visible;
}
.lv-portfolio-img-1-3 {
  height: 300px;
  position: relative;
}
.lv-portfolio-img-1-3::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  background-color: var(--clr-common-black);
  content: "";
}
.lv-portfolio-img-1-3:hover::after {
  opacity: 0.35;
  visibility: visible;
}
.lv-portfolio-link-1-3 {
  background-color: var(--clr-common-white);
  width: 110px;
  height: 110px;
  color: var(--clr-common-black);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lv-portfolio-link-1-3 i {
  font-size: 25px;
  margin-bottom: 6px;
}
.lv-portfolio-link-1-3 span {
  font-size: 12px;
  display: inline-block;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
}
.lv-portfolio-details-title-1-4 {
  font-size: 50px;
  line-height: 1.3;
  margin-bottom: 8px;
}
.lv-portfolio-member-name-1-4 {
  font-size: 18px;
  margin-bottom: 0;
  display: block;
}
.lv-portfolio-date-1-4 {
  font-size: 12px;
  display: block;
  margin-bottom: 0;
  line-height: 1.6;
}
.lv-portfolio-meta-list-1-4 ul li {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  list-style: none;
}
.lv-portfolio-meta-list-1-4 ul li span {
  color: var(--clr-common-black);
  font-weight: 400;
}

.lv-p-details-grid-item-1-4-1 {
  height: 450px;
}
.lv-p-details-grid-item-1-4-2 {
  height: 515px;
}
.lv-p-details-grid-item-1-4-3 {
  height: 250px;
}
.lv-p-details-grid-item-1-4-4 {
  height: 450px;
}

.portfolio-nav-link-1-4 svg {
  transform: translateY(-1px);
}
.portfolio-nav-link-1-4 span {
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 1px;
}
.portfolio-nav-link-1-4:hover span {
  color: var(--clr-common-black);
}
.portfolio-nav-link-1-4:hover path {
  fill: var(--clr-common-black);
}
.portfolio-nav-link.portfolio-nav-link-right span {
  margin-left: 0px;
  margin-right: 1px;
}

.portfolio-nav-arrows span {
  width: 250px;
  background-color: var(--clr-body-grey-3);
  height: 1px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-nav-arrows span {
    width: 180px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-nav-arrows span {
    width: 110px;
  }
}
@media (max-width: 767px) {
  .portfolio-nav-arrows span {
    display: none;
  }
}
.portfolio-nav-arrows span:first-child {
  position: absolute;
  left: 0;
  top: 18px;
}
.portfolio-nav-arrows span:last-child {
  position: absolute;
  right: 0;
  top: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .portfolio-nav-grid-1-4.pl-60.pr-60 {
    padding-left: 0;
    padding-right: 0;
  }
}

.portfolio-nav-grid-1-4 svg {
  overflow: visible;
}

.lv-header-sidebar-official-gallery-item {
  overflow: hidden;
  transition: all 0.3s;
  background-size: 150%;
}

.lv-header-sidebar-official-gallery-item:hover {
  background-size: 160%;
}

.lv-about-pata-img-wrap.lv-about-body-pata
  .lv-about-pata-parent:nth-child(odd) {
  width: 16px;
  height: 15px;
}

.lv-about-pata-img-wrap.lv-about-body-pata
  .lv-about-pata-parent:nth-child(odd)
  img {
  height: 100%;
}

.lv-about-pata-img-wrap.lv-about-body-pata
  .lv-about-pata-parent
  .lv-about-pata-rotate
  .askew {
  height: 100%;
}

/**
********************************************
************* 26.Contact
********************************************
*/
.get-in-touch-title-1-5 {
  font-size: 50px;
  margin-bottom: 0;
  line-height: 1;
}
@media (max-width: 767px) {
  .get-in-touch-title-1-5 {
    font-size: 40px;
  }
}
.get-in-touch-contact-tab-buttons-1-5 .nav {
  justify-content: center;
}
.get-in-touch-contact-tab-buttons-1-5 .nav button {
  display: inline-block;
  height: 40px;
  padding: 0 30px;
  border: 1px solid var(--clr-border-light);
  font-size: 14px;
  color: var(--clr-common-black);
  margin: 0 10px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .get-in-touch-contact-tab-buttons-1-5 .nav button {
    padding: 0 23px;
    margin: 0 4px;
    font-size: 13px;
  }
}
.get-in-touch-contact-tab-buttons-1-5 .nav button.active {
  border-color: transparent;
  background-color: var(--clr-common-black);
  color: var(--clr-common-white);
}
.get-in-touch-box-1-5 {
  border: 1px solid var(--clr-border-light);
  padding: 40px 30px;
}
.get-in-touch-box-title-1-5 {
  font-size: 18px;
  margin-bottom: 9px;
}
.get-in-touch-box-links-1-5 a,
.get-in-touch-box-links-1-5 span {
  display: block;
  color: var(--clr-body-grey-4);
  margin-bottom: 6px;
  line-height: 1;
}

.lv-contact-single-input-wrap-1-5 input,
.lv-contact-single-input-wrap-1-5 textarea {
  padding: 20px;
}
.lv-contact-single-input-wrap-1-5 .nice-select .current {
  transform: translateY(3px);
  display: inline-block;
}
.lv-contact-single-input-wrap-1-5 input,
.lv-contact-single-input-wrap-1-5 .nice-select,
.lv-contact-single-input-wrap-1-5 textarea {
  height: 50px;
  width: 100%;
  border: 1px solid var(--clr-border-light);
  border-radius: 0;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--clr-common-black);
}
.lv-contact-single-input-wrap-1-5 input:focus,
.lv-contact-single-input-wrap-1-5 input:focus-visible,
.lv-contact-single-input-wrap-1-5 .nice-select:focus,
.lv-contact-single-input-wrap-1-5 .nice-select:focus-visible,
.lv-contact-single-input-wrap-1-5 textarea:focus,
.lv-contact-single-input-wrap-1-5 textarea:focus-visible {
  border-color: var(--clr-common-black);
  border-width: 1px;
  border-radius: 0;
  outline: none;
}
.lv-contact-single-input-wrap-1-5 input::placeholder,
.lv-contact-single-input-wrap-1-5 .nice-select::placeholder,
.lv-contact-single-input-wrap-1-5 textarea::placeholder {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--clr-common-black);
}
.lv-contact-single-input-wrap-1-5 textarea {
  height: 130px;
}

.lv-contact-map-frame-1-5 {
  position: relative;
  line-height: 1;
}
.lv-contact-map-frame-1-5 iframe {
  width: 100%;
  height: 400px;
  border: 0;
}

.lv-contact-img-1-5 {
  height: 417px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-contact-img-1-5 {
    height: 533px;
  }
}

/**
********************************************
************* 27.Product
********************************************
*/
.lv-product-box-img-1-6 {
  height: 485px;
  overflow: hidden;
  position: relative;
  transition: all 0.9s;
  background-color: var(--clr-theme-primary);
  background-size: 110%;
  background-position: top center;
  transition: all 0.5s;
}
.lv-product-box-img-1-6:hover {
  background-size: 120%;
}
.lv-product-box-img-1-6.lv-height-2 {
  height: 350px;
}
@media (max-width: 767px) {
  .lv-product-box-img-1-6 {
    height: 325px;
  }
}
.lv-product-box-img-1-6 .lv-product-box-cart-link-1-6 {
  position: absolute;
  left: 0;
  bottom: -50px;
  right: 0;
  transition: all 0.3s;
}
.lv-product-box-img-1-6 .lv-product-box-badge-1-6 {
  position: absolute;
  left: 0;
  top: 0;
}
.lv-product-box-img-1-6:hover .lv-product-box-cart-link-1-6 {
  bottom: 0;
  color: var(--clr-common-black);
}
.lv-product-box-img-height-2 {
  height: 350px;
}
.lv-product-box-cart-link-1-6 {
  font-size: 16px;
  text-transform: uppercase;
  color: var(--clr-common-black);
  display: block;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 10px 30px;
}
.lv-product-box-title-1-6 {
  font-size: 16px;
  font-family: "Muli", sans-serif;
  text-transform: uppercase;
  margin-bottom: 3px;
}
.lv-product-box-content-1-6 {
  padding: 15px;
}
.lv-product-box-price-1-6 span {
  font-size: 16px;
  color: var(--clr-common-black);
}
.lv-product-box-price-1-6 del {
  font-size: 14px;
  color: var(--clr-body-grey);
}
.lv-product-box-badge-1-6 {
  font-size: 12px;
  color: var(--clr-common-black);
  text-transform: uppercase;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  padding: 0 15px;
  background-color: var(--clr-common-white);
}

.lv-shop-pagination-1-6 a {
  display: inline-block;
  width: 50px;
  height: 50px;
  font-weight: 400;
  line-height: 45px;
  text-align: center;
  font-size: 16px;
  color: var(--clr-common-black);
  border: 1px solid var(--clr-border-soft-2);
}
.lv-shop-pagination-1-6 a:hover,
.lv-shop-pagination-1-6 a.active {
  border-color: var(--clr-common-black);
}
.lv-shop-pagination-1-6 a.paginate-left,
.lv-shop-pagination-1-6 a.paginate-right {
  font-size: 26px;
  line-height: 36px;
}
.lv-shop-pagination-1-6 a.paginate-left i,
.lv-shop-pagination-1-6 a.paginate-right i {
  transform: translateY(4px);
}

.lv-shop-product-count-1-6 span {
  font-size: 14px;
  display: block;
}
.lv-shop-list-dropdown-1-6 .nice-select {
  float: none;
  display: inline-block;
  padding-right: 40px;
  border-radius: 0;
  border: 0;
}
@media (max-width: 767px) {
  .lv-shop-list-dropdown-1-6 .nice-select {
    padding-left: 0;
  }
}
.lv-shop-list-dropdown-1-6 .nice-select::after {
  margin-top: -2px;
  right: 3px;
  border-bottom-color: var(--clr-body-gray);
  border-right-color: var(--clr-body-gray);
}
.lv-shop-list-dropdown-1-6 .nice-select .current {
  font-size: 14px;
  color: var(--clr-body-grey);
}

.product__desc-info ul li:nth-child(2n + 1) {
  background: #f7f7f7;
}

.product__desc-info ul li h6 {
  font-size: 16px;
  color: #454545;
  margin-bottom: 0;
  display: inline-block;
  width: 25%;
}
@media (max-width: 767px) {
  .product__desc-info ul li h6 {
    width: 100%;
  }
}

.product__desc-info ul li span {
  font-size: 16px;
  color: #454545;
}

.product__desc-info ul li {
  display: flex;
  align-items: center;
  padding: 15px 30px;
}
@media (max-width: 767px) {
  .product__desc-info ul li {
    display: block;
  }
}

.comments-avatar img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.latest-comments li:first-child .comments-box {
  border-top: 0;
  padding-top: 0;
}

.comments-box {
  border-top: 1px solid #e3e3e3;
  padding: 30px 0;
}

.comments-avatar {
  float: left;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .comments-avatar {
    float: none;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.comments-text {
  overflow: hidden;
  padding-top: 4px;
}

.avatar-name {
  margin-bottom: 3px;
  overflow: hidden;
  position: relative;
}

.avatar-name h5 {
  font-size: 18px;
  margin-bottom: 0px;
  display: inline-block;
  color: var(--clr-common-black);
}

.avatar-name span {
  color: #5b5b5b;
  text-transform: capitalize;
  display: block;
  font-size: 14px;
  font-weight: 400;
}

.user-rating ul li {
  display: inline-block;
}

.user-rating ul li a {
  color: var(--clr-common-black);
}

.comments-text p {
  margin-bottom: 15px;
  margin-top: 8px;
  color: #5b5b5b;
}

.reply-2 {
  font-weight: 600;
  font-size: 14px;
  color: var(--clr-common-black);
}

.latest-comments li {
  list-style: none;
}

.latest-comments li.children {
  margin-left: 100px;
}
@media (max-width: 767px) {
  .latest-comments li.children {
    margin-left: 0;
  }
}

.post-rating ul li {
  display: inline-block;
  list-style: none;
}

.p-relative {
  position: relative;
}

.conatct-post-form input,
.conatct-post-form textarea {
  height: 50px;
  width: 100%;
  border: 1px solid var(--clr-border-light);
  border-radius: 0;
  font-size: 14px;
  text-transform: uppercase;
  padding: 20px;
  background-color: transparent;
  color: var(--clr-common-black);
}
.conatct-post-form input:focus,
.conatct-post-form input:focus-within,
.conatct-post-form textarea:focus,
.conatct-post-form textarea:focus-within {
  outline: 0;
  border-color: transparent;
}

.conatct-post-form textarea {
  height: 130px;
}

.add-cart-btn {
  display: inline-block;
  height: 48px;
  line-height: 44px;
  text-align: center;
  padding: 0 70px;
  color: var(--clr-common-white);
  background: var(--clr-common-black);
  border: 2px solid var(--clr-common-black);
  font-size: 14px;
}

.add-cart-btn:hover {
  background: var(--clr-common-white);
  color: var(--clr-common-black);
}

.conatct-post-form textarea:focus {
  border-color: transparent;
}

.conatct-post-form input:focus,
.conatct-post-form input:focus-visible {
  border-color: transparent;
  outline: none;
}

@media (max-width: 767px) {
  .post-comments-form.pl-20 {
    padding-left: 0;
  }
}

.latest-comments .user-rating {
  margin-top: 5px;
}

/**
********************************************
************* 28.Widget
********************************************
*/
.lv-search-widget-form-1-6 {
  position: relative;
}
.lv-search-widget-form-1-6 input[type="text"] {
  border: 0;
  height: 50px;
  width: 100%;
  background-color: var(--clr-body-grey-5);
  padding: 0 20px;
  font-size: 12px;
  color: var(--clr-body-grey-6);
}
.lv-search-widget-form-1-6 input[type="text"]:focus-visible {
  outline: 0;
}
.lv-search-widget-form-1-6 input[type="text"]:placeholder {
  color: var(--clr-body-grey-6);
}
.lv-search-widget-form-1-6 button[type="submit"],
.lv-search-widget-form-1-6 input[type="submit"] {
  position: absolute;
  top: 0;
  background-color: var(--clr-body-grey-5);
  right: 0;
  padding: 0 20px;
  height: 100%;
}
.lv-search-widget-form-1-6 button[type="submit"]:focus-visible,
.lv-search-widget-form-1-6 input[type="submit"]:focus-visible {
  outline: 0;
}
.lv-search-widget-form-1-6 button[type="submit"] i,
.lv-search-widget-form-1-6 input[type="submit"] i {
  font-size: 14px;
  color: var(--clr-body-grey);
}

#lv-slider-range {
  border: 0;
  border-radius: 0;
  height: 1px;
  background-color: var(--clr-body-grey-7);
}

.lv-slider-range .ui-slider-handle {
  width: 10px;
  left: 15%;
  height: 10px;
  border-radius: 50%;
  border: 0;
  background-color: var(--clr-body-grey);
}
.lv-slider-range .ui-slider-handle:focus-visible {
  outline: 0;
}
.lv-slider-range .ui-slider-range.ui-corner-all {
  height: 3px;
  margin-top: -0.59px;
  background-color: var(--clr-body-grey);
}
.lv-slider-range input {
  color: var(--clr-common-black-2);
  border: 0;
  font-size: 14px;
}
.lv-slider-range input:focus {
  outline: 0;
}

.lv-widget-heading-1-6 {
  font-size: 16px;
  text-transform: uppercase;
}
.lv-widget-categories-list-1-6 ul li {
  list-style: none;
}
.lv-widget-categories-list-1-6 ul li:not(:last-child) {
  margin-bottom: 10px;
}
.lv-widget-categories-list-1-6 ul li a {
  font-size: 16px;
  color: var(--clr-common-black-3);
  text-transform: uppercase;
}
.lv-widget-top-product-img-1-6 {
  width: 80px;
  height: 100px;
  float: left;
  margin-right: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-widget-top-product-img-1-6 {
    margin-right: 13px;
  }
}
.lv-widget-top-product-single-1-6 {
  overflow: hidden;
}
.lv-widget-top-product-single-1-6 .lv-product-box-title-1-6 {
  line-height: 26px;
  margin-bottom: 0;
}
.lv-widget-top-product-single-1-6 .lv-product-box-title-1-6:hover {
  color: var(--clr-theme-primary);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .lv-widget-top-product-single-1-6 .lv-product-box-title-1-6 {
    font-size: 15px;
  }
}
.lv-widget-top-product-content-1-6 {
  overflow: hidden;
  padding-top: 7px;
}
.lv-widget-filter-color-list-1-6 ul li {
  list-style: none;
  margin-bottom: 6px;
}
.lv-widget-filter-color-list-1-6 ul li a {
  font-size: 16px;
  color: var(--clr-common-black-3);
  display: inline-block;
}
.lv-widget-filter-color-list-1-6 ul li a span {
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
  transform: translateY(2px);
  margin-right: 8px;
}

.lv-amount-filter {
  font-size: 14px;
  float: right;
  text-transform: uppercase;
  color: var(--clr-theme-primary);
  font-weight: 400;
}

.mb-43 {
  margin-bottom: 43px;
}

.lv-rating i {
  font-size: 11px;
  color: var(--clr-body-grey-6);
}
.lv-rating i.active {
  color: var(--clr-theme-primary);
}

.pt-4-px {
  padding-top: 4px;
}

.lv-rating {
  line-height: 1;
}

.tagcloud a {
  font-size: 16px;
  color: var(--clr-body-grey-6);
  display: inline-block;
  position: relative;
  padding-right: 5px;
}
.tagcloud a:hover {
  color: var(--clr-common-black);
}
.tagcloud a::after {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 16px;
  content: ",";
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .lv-widget-left-padding-1-6 {
    padding-left: 0;
  }
}

.lv-blog-author-widget-img-6 {
  height: 300px;
  background-position: top center;
}

/**
********************************************
************* 29.Service Details
********************************************
*/
.lv-shop-details-product-tabs-1-7 {
  float: left;
  max-width: 150px;
  margin-right: 15px;
}
@media (max-width: 767px) {
  .lv-shop-details-product-tabs-1-7 {
    max-width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .lv-shop-details-product-tabs-1-7 .nav {
    grid-gap: 14px;
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .lv-shop-details-product-tabs-1-7 .nav > * {
    flex: 0 0 30%;
  }
}
.lv-shop-details-product-tabs-1-7 button img {
  width: 150px;
  height: 180px;
  object-fit: cover;
  background-color: var(--clr-theme-primary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-shop-details-product-tabs-1-7 button img {
    height: 147px;
  }
}
@media (max-width: 767px) {
  .lv-shop-details-product-tabs-1-7 button img {
    height: 110px;
  }
}
.lv-shop-details-img-1-7 {
  height: 570px;
  overflow: hidden;
  background-color: var(--clr-theme-primary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-shop-details-img-1-7 {
    height: 470px;
  }
}
@media (max-width: 767px) {
  .lv-shop-details-img-1-7 {
    height: 380px;
    width: 100%;
  }
}
.lv-shop-details-price-1-7 {
  font-size: 18px;
  font-weight: 600;
  color: var(--clr-common-black);
  display: block;
  margin-bottom: 7px;
}
.lv-shop-details-rating .rating {
  display: inline-block;
  margin-right: 6px;
}
.lv-shop-details-rating .review-label {
  font-size: 12px;
  display: inline-block;
}
.lv-shop-details-quantity-form-label-1-7 {
  display: inline-block;
  font-family: "monotype-corsiva", sans-serif;
  font-size: 20px;
  color: var(--clr-body-grey);
}
.lv-shop-details-quantity-form-1-7 {
  position: relative;
}
.lv-shop-details-quantity-form-1-7 input[type="number"] {
  width: 128px;
  height: 50px;
  text-align: center;
  font-size: 18px;
  padding: 0 31px;
  color: var(--clr-common-black);
  border: 1px solid var(--clr-border-light_4);
}
.lv-shop-details-quantity-form-1-7 input[type="number"]:focus-visible {
  outline: none;
}
.lv-shop-details-quantity-plus {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: none;
  padding-left: 20px;
  padding-right: 10px;
}
.lv-shop-details-quantity-plus i {
  font-size: 13px;
}
.lv-shop-details-quantity-minus {
  position: absolute;
  right: 0;
  display: none;
  top: 0;
  bottom: 0;
  padding-right: 20px;
  padding-left: 10px;
}
.lv-shop-details-quantity-minus i {
  font-size: 13px;
}
.lv-shop-details-meta-1-7 ul li {
  list-style: none;
  font-size: 14px;
  color: var(--clr-common-black);
}
.lv-shop-details-product-tab-buttons-1-7 {
  border-bottom: 1px solid var(--clr-body-grey-9);
  padding-bottom: 7px;
  margin-bottom: 28px;
}
.lv-shop-details-product-tab-buttons-1-7 button {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--clr-common-black);
  display: inline-block;
  padding: 0 15px;
  position: relative;
}
.lv-shop-details-product-tab-buttons-1-7 button:hover::after,
.lv-shop-details-product-tab-buttons-1-7 button.active::after {
  opacity: 1;
  visibility: visible;
}
.lv-shop-details-product-tab-buttons-1-7 button::after {
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  height: 1px;
  transition: all 0.3s;
  background-color: var(--clr-common-black);
  content: "";
}
.lv-shop-details-product-tab-buttons-1-7 button:not(:last-child) {
  margin-right: 28px;
}
@media (max-width: 767px) {
  .lv-shop-details-product-tab-buttons-1-7 button:not(:last-child) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .lv-shop-details-product-tab-buttons-1-7 button {
    margin-bottom: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .lv-shop-details-product-tab-buttons-1-7 button {
    margin-bottom: 0;
  }
}

.lv-product-stock-1-7 {
  display: inline-block;
  height: 25px;
  line-height: 22px;
  border: 1px solid var(--clr-border-light_3);
  font-size: 14px;
  padding: 0 12px;
}
.lv-product-stock-title-1-7 {
  font-size: 50px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .lv-product-stock-title-1-7 {
    font-size: 34px;
    margin-bottom: 9px;
  }
}

.mr-17 {
  margin-right: 17px;
}

.mb-37 {
  margin-bottom: 37px;
}

.lv-has-cart-margin-1-7 {
  margin-left: 15px;
  transform: translateY(-2px);
}
@media (max-width: 767px) {
  .lv-has-cart-margin-1-7 {
    margin-left: 0;
    transform: translateY(0);
    margin-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lv-shop-details-right-content-space-1-7 {
    padding-left: 30px;
  }
}

/**
********************************************
************* 30.Team
********************************************
*/
.team__area {
  position: relative;
}
.team__area::after {
  position: absolute;
  content: "";
  bottom: -25px;
  left: 0;
  width: 100%;
  height: 140%;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.team__item {
  padding: 30px 35px;
  background: #423d7c;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.team__item::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.team__item:hover::after {
  opacity: 1;
}
.team__item:hover .team__info {
  top: 35px;
  visibility: visible;
  opacity: 1;
}
.team__item:hover .team__social {
  bottom: 35px;
  visibility: visible;
  opacity: 1;
}

.team__item-add {
  background: #ffffff;
}
.team__item-add::after {
  display: none;
}

.team__add {
  padding: 113px 0;
}
.team__add a {
  font-size: 50px;
  color: #9895b4;
}
.team__add a:hover {
  color: var(--clr-theme-primary);
}

div.team__thumb img {
  width: 200px;
  height: 200px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.team__content h3 {
  font-size: 22px;
  margin-bottom: 0;
  color: #ffffff;
  text-transform: capitalize;
}
.team__content span {
  font-size: 15px;
  color: #cac9d3;
}

.team__info {
  position: absolute;
  top: 10px;
  left: 30px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.team__info h3 {
  font-size: 22px;
  margin-bottom: 0;
  color: #ffffff;
  text-transform: capitalize;
}
.team__info span {
  font-size: 15px;
  color: #cac9d3;
}

.team__social {
  position: absolute;
  bottom: 10px;
  left: 30px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.team__social ul li {
  display: inline-block;
  margin-right: 7px;
}
.team__social ul li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #7b23de;
  font-size: 14px;
  color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.team__social ul li a:hover {
  background: #ffffff;
  color: #7b23de;
}

.team__details-inner {
  -webkit-box-shadow: 0px 50px 80px 0px rgba(3, 0, 53, 0.1);
  -moz-box-shadow: 0px 50px 80px 0px rgba(3, 0, 53, 0.1);
  box-shadow: 0px 50px 80px 0px rgba(3, 0, 53, 0.1);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.team__details-shape {
  right: -11%;
  bottom: -16%;
  z-index: -1;
}

.team__details-img img {
  -webkit-border-radius: 30px 0 0 30px;
  -moz-border-radius: 30px 0 0 30px;
  border-radius: 30px 0 0 30px;
}

.team__details-content > span {
  font-size: 20px;
  display: inline-block;
  margin-bottom: 7px;
}
.team__details-content h3 {
  font-size: 30px;
  margin-bottom: 15px;
}
.team__details-content p {
  margin-bottom: 35px;
  padding-right: 80px;
}

.team__details-contact ul li {
  margin-bottom: 8px;
  display: flex;
}
.team__details-contact ul li .icon i {
  line-height: 27px;
  color: var(--clr-theme-primary);
  margin-right: 15px;
}
.team__details-contact ul li .text span {
  font-weight: 500;
}

.team__details-social ul li {
  display: inline-block;
  margin-right: 7px;
}
.team__details-social ul li a {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f1f1f1;
  font-size: 14px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.team__details-social ul li a:hover {
  background: var(--clr-theme-primary);
  color: #ffffff;
}

.team__details-info h4 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 25px;
}
.team__details-info p {
  margin-bottom: 25px;
}

.team-details-author-main-box-1-1 {
  border-radius: 30px;
  -webkit-box-shadow: 0px 50px 80px 0px rgba(3, 0, 53, 0.1);
  -moz-box-shadow: 0px 50px 80px 0px rgba(3, 0, 53, 0.1);
  box-shadow: 0px 50px 80px 0px rgba(3, 0, 53, 0.1);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__area::after {
    height: 132%;
  }

  .team__details-shape {
    right: 0;
  }

  .team__details-img {
    margin-right: 30px;
  }

  .team__details-content {
    padding-top: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .team__details-shape {
    right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__details-shape {
    right: -3%;
    bottom: -7%;
  }

  .team__details-img {
    margin-right: 0px;
  }

  .team__details-content {
    padding: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team__details-shape {
    right: -3%;
    bottom: -7%;
  }

  .team__details-img {
    margin-right: 0px;
  }

  .team__details-content {
    padding: 40px;
  }
}
@media (max-width: 575px) {
  .team__details-shape {
    right: -3%;
    bottom: -5%;
  }

  .team__details-img {
    margin-right: 0px;
  }

  .team__details-content {
    padding: 40px;
  }
}
/*---------------------------------------------------*/
/*  mouse cursor animation
/*---------------------------------------------------*/
.mouseCursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  transform: translateZ(0);
  visibility: hidden;
}

.cursor-inner {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  z-index: 10000001;
  background-color: #eb9309;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cursor-inner.cursor-hover {
  margin-left: -30px;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background-color: #eb9309;
  opacity: 0.2;
}

.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border: 2px solid #eb9309;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  transition: all 0.08s ease-out;
}

.cursor-outer.cursor-hover {
  opacity: 0;
}

.mim_tm_all_wrap[data-magic-cursor="hide"] .mouseCursor {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1111;
}

/*# sourceMappingURL=main.css.map */

/* Back to top */
.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(95, 58, 252, 0.2);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: "\f176";
  font-family: "Font Awesome 5 Pro";
  text-align: center;
  line-height: 46px;
  font-size: 20px;
  color: #2c3941;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #2c3941;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

/* Default css */

.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-105 {
  margin-top: 105px;
}
.mt-110 {
  margin-top: 110px;
}
.mt-115 {
  margin-top: 115px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-125 {
  margin-top: 125px;
}
.mt-130 {
  margin-top: 130px;
}
.mt-135 {
  margin-top: 135px;
}
.mt-140 {
  margin-top: 140px;
}
.mt-145 {
  margin-top: 145px;
}
.mt-150 {
  margin-top: 150px;
}
.mt-155 {
  margin-top: 155px;
}
.mt-160 {
  margin-top: 160px;
}
.mt-165 {
  margin-top: 165px;
}
.mt-170 {
  margin-top: 170px;
}
.mt-175 {
  margin-top: 175px;
}
.mt-180 {
  margin-top: 180px;
}
.mt-185 {
  margin-top: 185px;
}
.mt-190 {
  margin-top: 190px;
}
.mt-195 {
  margin-top: 195px;
}
.mt-200 {
  margin-top: 200px;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-105 {
  margin-bottom: 105px;
}
.mb-110 {
  margin-bottom: 110px;
}
.mb-115 {
  margin-bottom: 115px;
}
.mb-120 {
  margin-bottom: 120px;
}
.mb-125 {
  margin-bottom: 125px;
}
.mb-130 {
  margin-bottom: 130px;
}
.mb-135 {
  margin-bottom: 135px;
}
.mb-140 {
  margin-bottom: 140px;
}
.mb-145 {
  margin-bottom: 145px;
}
.mb-150 {
  margin-bottom: 150px;
}
.mb-155 {
  margin-bottom: 155px;
}
.mb-160 {
  margin-bottom: 160px;
}
.mb-165 {
  margin-bottom: 165px;
}
.mb-170 {
  margin-bottom: 170px;
}
.mb-175 {
  margin-bottom: 175px;
}
.mb-180 {
  margin-bottom: 180px;
}
.mb-185 {
  margin-bottom: 185px;
}
.mb-190 {
  margin-bottom: 190px;
}
.mb-195 {
  margin-bottom: 195px;
}
.mb-200 {
  margin-bottom: 200px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-55 {
  margin-left: 55px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-65 {
  margin-left: 65px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-75 {
  margin-left: 75px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-85 {
  margin-left: 85px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-95 {
  margin-left: 95px;
}
.ml-100 {
  margin-left: 100px;
}
.ml-105 {
  margin-left: 105px;
}
.ml-110 {
  margin-left: 110px;
}
.ml-115 {
  margin-left: 115px;
}
.ml-120 {
  margin-left: 120px;
}
.ml-125 {
  margin-left: 125px;
}
.ml-130 {
  margin-left: 130px;
}
.ml-135 {
  margin-left: 135px;
}
.ml-140 {
  margin-left: 140px;
}
.ml-145 {
  margin-left: 145px;
}
.ml-150 {
  margin-left: 150px;
}
.ml-155 {
  margin-left: 155px;
}
.ml-160 {
  margin-left: 160px;
}
.ml-165 {
  margin-left: 165px;
}
.ml-170 {
  margin-left: 170px;
}
.ml-175 {
  margin-left: 175px;
}
.ml-180 {
  margin-left: 180px;
}
.ml-185 {
  margin-left: 185px;
}
.ml-190 {
  margin-left: 190px;
}
.ml-195 {
  margin-left: 195px;
}
.ml-200 {
  margin-left: 200px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-55 {
  margin-right: 55px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-65 {
  margin-right: 65px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-75 {
  margin-right: 75px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-85 {
  margin-right: 85px;
}
.mr-90 {
  margin-right: 90px;
}
.mr-95 {
  margin-right: 95px;
}
.mr-100 {
  margin-right: 100px;
}
.mr-105 {
  margin-right: 105px;
}
.mr-110 {
  margin-right: 110px;
}
.mr-115 {
  margin-right: 115px;
}
.mr-120 {
  margin-right: 120px;
}
.mr-125 {
  margin-right: 125px;
}
.mr-130 {
  margin-right: 130px;
}
.mr-135 {
  margin-right: 135px;
}
.mr-140 {
  margin-right: 140px;
}
.mr-145 {
  margin-right: 145px;
}
.mr-150 {
  margin-right: 150px;
}
.mr-155 {
  margin-right: 155px;
}
.mr-160 {
  margin-right: 160px;
}
.mr-165 {
  margin-right: 165px;
}
.mr-170 {
  margin-right: 170px;
}
.mr-175 {
  margin-right: 175px;
}
.mr-180 {
  margin-right: 180px;
}
.mr-185 {
  margin-right: 185px;
}
.mr-190 {
  margin-right: 190px;
}
.mr-195 {
  margin-right: 195px;
}
.mr-200 {
  margin-right: 200px;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-55 {
  padding-top: 55px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-85 {
  padding-top: 85px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-95 {
  padding-top: 95px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-105 {
  padding-top: 105px;
}
.pt-110 {
  padding-top: 110px;
}
.pt-115 {
  padding-top: 115px;
}
.pt-120 {
  padding-top: 120px;
}
.pt-125 {
  padding-top: 125px;
}
.pt-130 {
  padding-top: 130px;
}
.pt-135 {
  padding-top: 135px;
}
.pt-140 {
  padding-top: 140px;
}
.pt-145 {
  padding-top: 145px;
}
.pt-150 {
  padding-top: 150px;
}
.pt-155 {
  padding-top: 155px;
}
.pt-160 {
  padding-top: 160px;
}
.pt-165 {
  padding-top: 165px;
}
.pt-170 {
  padding-top: 170px;
}
.pt-175 {
  padding-top: 175px;
}
.pt-180 {
  padding-top: 180px;
}
.pt-185 {
  padding-top: 185px;
}
.pt-190 {
  padding-top: 190px;
}
.pt-195 {
  padding-top: 195px;
}
.pt-200 {
  padding-top: 200px;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-85 {
  padding-bottom: 85px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-95 {
  padding-bottom: 95px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-105 {
  padding-bottom: 105px;
}
.pb-110 {
  padding-bottom: 110px;
}
.pb-115 {
  padding-bottom: 115px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pb-125 {
  padding-bottom: 125px;
}
.pb-130 {
  padding-bottom: 130px;
}
.pb-135 {
  padding-bottom: 135px;
}
.pb-140 {
  padding-bottom: 140px;
}
.pb-145 {
  padding-bottom: 145px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pb-155 {
  padding-bottom: 155px;
}
.pb-160 {
  padding-bottom: 160px;
}
.pb-165 {
  padding-bottom: 165px;
}
.pb-170 {
  padding-bottom: 170px;
}
.pb-175 {
  padding-bottom: 175px;
}
.pb-180 {
  padding-bottom: 180px;
}
.pb-185 {
  padding-bottom: 185px;
}
.pb-190 {
  padding-bottom: 190px;
}
.pb-195 {
  padding-bottom: 195px;
}
.pb-200 {
  padding-bottom: 200px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-55 {
  padding-left: 55px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-65 {
  padding-left: 65px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-75 {
  padding-left: 75px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-85 {
  padding-left: 85px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-100 {
  padding-left: 100px;
}
.pl-105 {
  padding-left: 105px;
}
.pl-110 {
  padding-left: 110px;
}
.pl-115 {
  padding-left: 115px;
}
.pl-120 {
  padding-left: 120px;
}
.pl-125 {
  padding-left: 125px;
}
.pl-130 {
  padding-left: 130px;
}
.pl-135 {
  padding-left: 135px;
}
.pl-140 {
  padding-left: 140px;
}
.pl-145 {
  padding-left: 145px;
}
.pl-150 {
  padding-left: 150px;
}
.pl-155 {
  padding-left: 155px;
}
.pl-160 {
  padding-left: 160px;
}
.pl-165 {
  padding-left: 165px;
}
.pl-170 {
  padding-left: 170px;
}
.pl-175 {
  padding-left: 175px;
}
.pl-180 {
  padding-left: 180px;
}
.pl-185 {
  padding-left: 185px;
}
.pl-190 {
  padding-left: 190px;
}
.pl-195 {
  padding-left: 195px;
}
.pl-200 {
  padding-left: 200px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-65 {
  padding-right: 65px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-75 {
  padding-right: 75px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-85 {
  padding-right: 85px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-95 {
  padding-right: 95px;
}
.pr-100 {
  padding-right: 100px;
}
.pr-105 {
  padding-right: 105px;
}
.pr-110 {
  padding-right: 110px;
}
.pr-115 {
  padding-right: 115px;
}
.pr-120 {
  padding-right: 120px;
}
.pr-125 {
  padding-right: 125px;
}
.pr-130 {
  padding-right: 130px;
}
.pr-135 {
  padding-right: 135px;
}
.pr-140 {
  padding-right: 140px;
}
.pr-145 {
  padding-right: 145px;
}
.pr-150 {
  padding-right: 150px;
}
.pr-155 {
  padding-right: 155px;
}
.pr-160 {
  padding-right: 160px;
}
.pr-165 {
  padding-right: 165px;
}
.pr-170 {
  padding-right: 170px;
}
.pr-175 {
  padding-right: 175px;
}
.pr-180 {
  padding-right: 180px;
}
.pr-185 {
  padding-right: 185px;
}
.pr-190 {
  padding-right: 190px;
}
.pr-195 {
  padding-right: 195px;
}
.pr-200 {
  padding-right: 200px;
}

/* Flat Icons */

@font-face {
  font-family: "flaticon";
  src: url("../fonts/flaticon.ttf") format("truetype"),
    url("../fonts/flaticon.ttf") format("woff");
  /* url("../fonts/flaticon.woff2") format("woff2"),
    url("../fonts/flaticon.eot") format("embedded-opentype"),
    url("../fonts/flaticon.svg") format("svg"); */
}

i[class^="flaticon-"]:before,
i[class*=" flaticon-"]:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-align-left:before {
  content: "\f101";
}
.flaticon-play:before {
  content: "\f102";
}
.flaticon-bride:before {
  content: "\f103";
}
.flaticon-wedding-arch:before {
  content: "\f104";
}
.flaticon-photo-size:before {
  content: "\f105";
}
.flaticon-instagram:before {
  content: "\f106";
}
.flaticon-email:before {
  content: "\f107";
}
.flaticon-quotation-marks:before {
  content: "\f108";
}
.flaticon-love:before {
  content: "\f109";
}
.flaticon-wedding:before {
  content: "\f10a";
}
.flaticon-magic-book:before {
  content: "\f10b";
}
.flaticon-front-camera:before {
  content: "\f10c";
}
.flaticon-bridesmaid:before {
  content: "\f10d";
}

/* My Css */
.lv-header-nav {
  transition: background-color 0.3s ease;
  z-index: 10; /* High z-index to keep it above other content */
}

.lv-header-nav.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: blue; /* Change the color on scroll */
  padding: 10px 0; /* Optional: add some padding */
}

/* Styling for other elements (optional, adjust as needed) */
.group:hover .group-hover {
  width: 8rem; /* Example hover effect on the hamburger spans */
}

.rotate-y-hover:hover {
  transform: rotateY(180deg);
  animation-duration: 1000ms;
  transition: transform 1000ms;
  cursor: pointer;
}
